import { RouterRoleMap, UserRole, errorPage, notNeedLoginPage, notShowLang, userLoginPage } from '@/constants/common';
import { RootState } from '@/redux/store';
import { useRouter } from 'next/router';
import { FC, PropsWithChildren, useState, useEffect, ReactNode } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Cookies from 'js-cookie';
import { Layout as AntLayout, Space, Dropdown, Avatar, Divider, Menu } from 'antd';
import cls from 'classnames';
import LoginLayout from './LoginLayout';
import styles from './Layout.module.scss';
import { formatAssetUrl, getParentContainer } from '@/utils/util';
import { loginUser, logoutUser } from '@/redux/slice/user';
import { SliderIcon } from '@/constants/icons';
import {
  AccountIcon,
  PromotionIcon,
  TransactionIcon,
  KocOffersIcon,
  KocOrdersIcon,
  KocProjectIcon,
  PartnerIcon,
  KocMediaAccountIcon,
  MyPaymentIcon,
  MyNotificationIcon,
} from '@/constants';
import { removeAuthCookies } from '@/utils/cookie';
import { clearAuth, setAuth } from '@/utils/request';
import { changeCollapsed } from '@/redux/slice/common';
import LangSelect from '@/components/intl/LangSelect';
import { useIntl, defineMessages } from 'react-intl';
import { notificationIntlMessgaes } from '@/page-components/notification/constants/intl';
import Bell from '@/components/notification/Bell';
import NotificationModal from '@/components/common/NotificationModal';
import { addGtagEvent, gaEventName } from '@/utils/ga';

const activeKeysObj: Record<string, string> = {
  '/advertising/partner/ugc': '/advertising/partner/ugc',
  '/advertising/partner/promotion': '/advertising/partner/promotion',
};

const intlMessages = defineMessages({
  accountTitle: {
    id: 'advertiser.account.title',
    defaultMessage: 'Account Management',
  },
  offerCenterTitle: {
    id: 'kol.center.offerTitle',
    defaultMessage: 'Offer Center',
  },
  offerBrowserTitle: {
    id: 'kol.offers.title',
    defaultMessage: 'Offer Browser',
  },
  promotionTitle: {
    id: 'advertiser.promotion.tabTitle',
    defaultMessage: 'General Business Campaigns',
  },
  ugcTitle: {
    id: 'advertiser.ugc.tabTitle',
    defaultMessage: 'UGC Engagement Campaigns',
  },
  promotionPartnerTitle: {
    id: 'advertiser.promotionKoc.tabTitle',
    defaultMessage: 'General Business Collaboration Influencers',
  },
  ugcPartnerTitle: {
    id: 'advertiser.ugcKoc.tabTitle',
    defaultMessage: 'UGC Engagement Challenge Creators',
  },
  campaignManagementTitle: {
    id: 'advertiser.campaign.campaignManagementTitle',
    defaultMessage: 'Campaigns',
  },
  collaborativeInfluencersTitle: {
    id: 'advertiser.campaign.collaborativeInfluencersTitle',
    defaultMessage: 'Influencers',
  },
  transactionTitle: {
    id: 'advertiser.transaction.title',
    defaultMessage: 'Transaction Inquiry',
  },
  centerTitle: {
    id: 'kol.center.title',
    defaultMessage: 'Personal Center',
  },
  trafficResources: {
    id: 'kol.center.trafficResources',
    defaultMessage: 'Social Authetication',
  },
  offerInvitation: {
    id: 'kol.center.offerInvitation',
    defaultMessage: 'Offer Invitation',
  },
  offerTitle: { id: 'kol.offer.title', defaultMessage: 'My Offers' },
  paymentTitle: { id: 'kol.payment.title', defaultMessage: 'My Wallet' },
  logout: {
    id: 'common.logout',
    defaultMessage: 'log out',
  },
  infoTitle: { id: 'kol.info.title', defaultMessage: 'Personal Info' },
  taskTitle: { id: 'kol.offer.pageTitle', defaultMessage: 'General Business Collaborations' },
  kocUgcTitle: { id: 'kol.offer.ugcTitle', defaultMessage: 'UGC Engagement Challenges' },
});

const { Header, Sider, Content } = AntLayout;
export interface RouterParams {
  key: string;
  icon?: JSX.Element;
  label?: ReactNode;
  role: (UserRole | null)[];
  children?: RouterParams[];
  disabled?: boolean;
  help?: string;
}

const Layout: FC<PropsWithChildren> = ({ children }) => {
  const { role, email } = useSelector((state: RootState) => state.user);
  const dispatch = useDispatch();
  const router = useRouter();
  let { pathname } = router;
  const [collapsed, setCollapsed] = useState<boolean>(false);
  const [selectedKeys, setSelectedKeys] = useState<string>(pathname);
  const { formatMessage } = useIntl();

  // header
  const showHeader = ![...userLoginPage, ...errorPage].includes(pathname);

  const ShowSider = ![...userLoginPage, ...errorPage].includes(pathname);

  const routes: RouterParams[] = [
    // 广告主路由
    {
      key: '/account-management',
      role: RouterRoleMap['/account-management'],
      icon: <AccountIcon />,
      label: <span>{formatMessage(intlMessages.accountTitle)}</span>,
    },
    {
      key: '/advertising',
      icon: <PromotionIcon />,
      label: <span>{formatMessage(intlMessages.campaignManagementTitle)}</span>,
      role: RouterRoleMap['/advertising'],
      children: [
        {
          key: '/advertising/promotion',
          label: <span>{formatMessage(intlMessages.promotionTitle)}</span>,
          role: RouterRoleMap['/advertising/promotion'],
        },
        {
          key: '/advertising/ugc',
          label: <span>{formatMessage(intlMessages.ugcTitle)}</span>,
          role: RouterRoleMap['/advertising/ugc'],
        },
      ],
    },
    {
      key: '/advertising/partner',
      icon: <PartnerIcon />,
      label: <span>{formatMessage(intlMessages.collaborativeInfluencersTitle)}</span>,
      role: RouterRoleMap['/advertising'],
      children: [
        {
          key: '/advertising/partner/promotion',
          label: <span>{formatMessage(intlMessages.promotionPartnerTitle)}</span>,
          role: RouterRoleMap['/advertising'],
        },
        {
          key: '/advertising/partner/ugc',
          label: <span>{formatMessage(intlMessages.ugcPartnerTitle)}</span>,
          role: RouterRoleMap['/advertising'],
        },
      ],
    },
    {
      key: '/transaction',
      role: RouterRoleMap['/transaction'],
      icon: <TransactionIcon />,
      label: <span>{formatMessage(intlMessages.transactionTitle)}</span>,
    },

    // KOC路由
    // {
    //   key: '/koc/offer',
    //   role: RouterRoleMap['/koc/mediaAccount'],
    //   icon: <KocTaskIcon />,
    //   label: <span>{formatMessage(intlMessages.offerCenterTitle)}</span>,
    //   children: [
    //     {
    //       key: '/koc/offers',
    //       label: <span>{formatMessage(intlMessages.offerBrowserTitle)}</span>,
    //       role: RouterRoleMap['/koc/mediaAccount'],
    //     },
    //     {
    //       key: '/koc/orders',
    //       label: <span>{formatMessage(intlMessages.offerInvitation)}</span>,
    //       role: RouterRoleMap['/koc/mediaAccount'],
    //     },
    //     {
    //       key: '/koc/tasks',
    //       role: RouterRoleMap['/koc/tasks'],
    //       label: <span>{formatMessage(intlMessages.offerTitle)}</span>,
    //     },
    //   ],
    // },
    {
      key: '/koc/offers',
      role: RouterRoleMap['/koc/mediaAccount'],
      icon: <KocOffersIcon />,
      label: <span>{formatMessage(intlMessages.offerBrowserTitle)}</span>,
    },
    {
      key: '/koc/orders',
      role: RouterRoleMap['/koc/mediaAccount'],
      icon: <KocOrdersIcon />,
      label: <span>{formatMessage(intlMessages.offerInvitation)}</span>,
    },
    {
      key: '/koc/task',
      role: RouterRoleMap['/koc/tasks'],
      icon: <KocProjectIcon />,
      label: <span>{formatMessage(intlMessages.offerTitle)}</span>,
      children: [
        {
          key: '/koc/tasks',
          label: <span>{formatMessage(intlMessages.taskTitle)}</span>,
          role: RouterRoleMap['/koc/tasks'],
        },
        {
          key: '/koc/ugcTasks',
          label: <span>{formatMessage(intlMessages.kocUgcTitle)}</span>,
          role: RouterRoleMap['/koc/ugcTasks'],
        },
      ],
    },
    {
      key: '/koc',
      role: RouterRoleMap['/koc/mediaAccount'],
      icon: <KocMediaAccountIcon />,
      label: <span>{formatMessage(intlMessages.centerTitle)}</span>,
      children: [
        {
          key: '/koc/mediaAccount',
          label: <span>{formatMessage(intlMessages.trafficResources)}</span>,
          role: RouterRoleMap['/koc/mediaAccount'],
        },
        {
          key: '/koc/info',
          label: <span>{formatMessage(intlMessages.infoTitle)}</span>,
          role: RouterRoleMap['/koc/mediaAccount'],
        },
      ],
    },
    {
      key: '/koc/payment',
      role: RouterRoleMap['/koc/payment'],
      icon: <MyPaymentIcon />,
      label: <span>{formatMessage(intlMessages.paymentTitle)}</span>,
    },
    // KOC/广告主
    {
      key: '/notification',
      role: RouterRoleMap['/notification'],
      icon: <MyNotificationIcon />,
      label: <span>{formatMessage(notificationIntlMessgaes.notifications)}</span>,
    },
  ]
    .filter((o: any) => o.role.includes(role))
    .map((item) => {
      if (item.children) {
        item.children = item.children.filter((o: any) => o.role.includes(role));
      }
      return item;
    });

  useEffect(() => {
    const siderCollapsed = sessionStorage.getItem('sider_collapsed') ?? 'false';
    setCollapsed(siderCollapsed === 'true');
    dispatch(changeCollapsed(siderCollapsed === 'true'));

    const email = Cookies.get('USER_EMAIL');
    const userId = Cookies.get('USER_ID');
    const userType = Cookies.get('USER_TYPE');
    const userStatus = Cookies.get('USER_STATUS');
    const authToken = Cookies.get('AUTH_TOKEN');
    const isLoggedIn = email && userId && userType && userStatus && authToken;
    //token  改变时同步redux
    isLoggedIn &&
      dispatch(
        loginUser({
          userId: Number(userId),
          userType: Number(userType),
          status: Number(userStatus),
          authorization: authToken,
          isLoggedIn: true,
          email,
        }),
      );
  }, [dispatch]);

  useEffect(() => {
    const pathBefore = pathname.split('?')[0];
    const paths = pathBefore.split('/');

    const activePath = '/' + paths[1] + (paths[2] ? '/' + paths[2] : '');

    setSelectedKeys(activeKeysObj[pathBefore] || activePath || pathname);
  }, [pathname]);

  function syncCollapsed(collapsed: boolean) {
    setCollapsed(collapsed);
    dispatch(changeCollapsed(collapsed));
    sessionStorage.setItem('sider_collapsed', collapsed + '');
  }

  function siderTrigger() {
    syncCollapsed(!collapsed);
  }

  function handleLoginLogout() {
    if (role && email) {
      dispatch(logoutUser());
    }
    removeAuthCookies();
    clearAuth();
    router.push('/login');
  }

  const headerHandlerPopoverContentRender = () => (
    <div className={cls('text-center', styles.dropdown)}>
      <div style={{ marginBottom: 8 }}>
        <Avatar className={styles.avatar}>{email?.slice(0, 2)}</Avatar>
      </div>
      <div>{email}</div>
      <Divider style={{ margin: '16px 0' }} type="horizontal" />
      <div>
        <a onClick={handleLoginLogout}>{formatMessage(intlMessages.logout)}</a>
      </div>
    </div>
  );

  if (userLoginPage.includes(pathname)) {
    return <LoginLayout>{children}</LoginLayout>;
  }

  if (notNeedLoginPage.includes(pathname)) {
    return (
      <div className={styles.notNeedLoginPage}>
        <AntLayout>{children}</AntLayout>
        {!notShowLang.includes(pathname) && (
          <div className={styles.langSelect}>
            <LangSelect />
          </div>
        )}
      </div>
    );
  }

  return (
    <AntLayout className={styles.layout}>
      {showHeader && (
        <Header className={styles.header}>
          <div
            style={{
              cursor: 'pointer',
            }}
            className={styles.logo}
            onClick={() => {
              router.push('/');
            }}
          >
            <img src={formatAssetUrl('/logo.svg')} alt="logo" height="40px" width="205px" />
          </div>
          <Space className={styles.handler}>
            <div className={styles.langSelect}>
              <Bell />
            </div>
            <div className={styles.langSelect}>
              <LangSelect />
            </div>
            <Dropdown getPopupContainer={getParentContainer} dropdownRender={headerHandlerPopoverContentRender}>
              <Avatar className={styles.avatar}>{email?.slice(0, 2)}</Avatar>
            </Dropdown>
          </Space>
        </Header>
      )}
      {ShowSider && (
        <div className={showHeader ? styles.container : `${styles.new_background} ${styles.container}`}>
          <Sider
            theme="light"
            collapsible
            collapsedWidth={64}
            collapsed={collapsed}
            onCollapse={(collapsed) => syncCollapsed(collapsed)}
            width={260}
            className={styles.sider}
            trigger={null}
          >
            <Menu
              mode="inline"
              selectedKeys={[selectedKeys]}
              className={styles.menu}
              defaultOpenKeys={['/koc', '/koc/offer']}
              onClick={({ key }) => {
                addGtagEvent({
                  event: gaEventName.CLICK,
                  options: {
                    click_id: 'menu_click',
                    user_email: email,
                    key,
                  },
                });
                router.push(key as string);
              }}
              items={routes}
            />
          </Sider>
          <div className={styles.siderTrigger} onClick={siderTrigger} style={{ width: collapsed ? 64 : 260 }}>
            <div className={styles.siderTriggerIcon}>
              <SliderIcon rotate={collapsed ? 180 : 0} />
            </div>
          </div>
        </div>
      )}
      <Content className={showHeader ? styles.showHeaderChildren : ''} id="wrapper">
        {children}

        <NotificationModal />
      </Content>
    </AntLayout>
  );
};

export default Layout;
