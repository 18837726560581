import { Popover } from 'antd';
import useLocale, { Locales } from '@/hooks/useLocal';
import { LocaleChangeIcon } from '@/constants';
import styles from './LangSelect.module.scss';
const languageLabels: Record<Locales, string> = {
  'zh-CN': '中文简体',
  'en-US': 'English',
  'ja-JP': '日本語',
};

const LangSelect: React.FC = () => {
  const { locale, setLocale } = useLocale();

  const handleChangeLanguage = (newLang: Locales) => {
    setLocale(newLang);
  };
  const content = (
    <div>
      {Object.keys(languageLabels).map((key) => (
        <div
          key={key}
          onClick={() => handleChangeLanguage(key as Locales)}
          style={{
            width: 88,
            height: 40,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            cursor: 'pointer',
            borderRadius: 4,
            backgroundColor: locale === key ? 'rgba(0, 0, 0, 0.04)' : '#fff',
          }}
        >
          {languageLabels[key as Locales]}
        </div>
      ))}
    </div>
  );

  return (
    <Popover content={content} placement="bottom" arrow={false} trigger="click">
      <div className={styles.locale}>
        <LocaleChangeIcon />
        {languageLabels[locale]}
        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
          <path
            d="M5.99425 6.55866L9.13211 4.0712C9.30384 3.96271 9.44434 3.97821 9.55362 4.11769L9.92829 4.58264C10.0376 4.72212 10.022 4.86161 9.88145 5.00109L6.18159 7.93026C6.05669 8.02325 5.93181 8.02325 5.80692 7.93026L2.10705 5.00109C1.98216 4.86161 1.96655 4.72212 2.06021 4.58264L2.43488 4.11769C2.55977 3.97821 2.70028 3.96271 2.85639 4.0712L5.99425 6.55866Z"
            fill="#808591"
          />
        </svg>
      </div>
    </Popover>
  );
};

export default LangSelect;
