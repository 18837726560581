import axios from 'axios';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useForceUpdate from './useForceUpdate';
import { tuple } from '@/utils/typeHelpers';
import { RootState } from '@/redux/store';
import { setLocal } from '@/redux/slice/user';
import getConfig from 'next/config';

export const locales = tuple('zh-CN', 'en-US', 'ja-JP');
export type Locales = (typeof locales)[number];
export const LOCALE_KEY = 'YOUDAOADS_KOC_LOCALE';

const { STATIC_ASSETS_URL } = getConfig().publicRuntimeConfig;
const messages: Record<string, any> = {};
const selector = (state: RootState) => state.user.locale;

const useLocale = () => {
  const locale = useSelector(selector);
  const dispatch = useDispatch();
  const forceUpdate = useForceUpdate();

  const handleSetLocale = (newLocale: Locales) => {
    dispatch(setLocal(newLocale));
  };

  useEffect(() => {
    const handleSaveLocale = async (newLocale: Locales) => {
      if (!newLocale) return;
      if (!messages[newLocale]) {
        try {
          const { data } = await axios.get(`${STATIC_ASSETS_URL}/intl/${newLocale}.json`, {});
          messages[newLocale] = data;
        } catch (e) {
          console.error(e);
        }
      }

      localStorage.setItem(LOCALE_KEY, newLocale);
      if (locale !== newLocale) {
        handleSetLocale(newLocale);
      } else {
        forceUpdate();
      }
    };
    handleSaveLocale(locale);
  }, [locale]);

  return { locale, setLocale: handleSetLocale, messages, locales };
};

export default useLocale;
