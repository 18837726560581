export const GA_ID = 'G-XZKG04D8LC'; //google analytics id

export const notNeedLoginPage = [
  '/login',
  '/register',
  '/register/validation',
  '/company-info',
  '/advertiser-activate',
  '/kol-activate',
  '/password-reset',
  '/password-reset/verification',
  '/thirdPartyAuth',
  '/404',
  '/invite',
  '/invite/kol',
  '/invite/offers',
  '/invite/detail/[id]',
  '/500',
];

export const userLoginPage = ['/login', '/register', '/company-info'];

export const notShowLang = ['/invite/kol', '/invite/offers', '/invite/detail/[id]'];

export const errorPage = ['/404', '/500'];

export enum UserRole {
  // ADMIN = 4,
  KOC_ADVERTISER = 61, //广告主
  KOC = 101,
}
export const RoleObject = {
  61: 'KOC_ADVERTISER',
  101: 'KOC',
};
export const RouterRoleMap: Record<string, UserRole[]> = {
  '/login': [UserRole.KOC, UserRole.KOC_ADVERTISER],
  '/account-management': [UserRole.KOC_ADVERTISER],
  '/advertising/promotion': [UserRole.KOC_ADVERTISER],
  '/advertising/ugc': [UserRole.KOC_ADVERTISER],
  '/advertising': [UserRole.KOC_ADVERTISER],
  '/transaction': [UserRole.KOC_ADVERTISER],
  '/notification': [UserRole.KOC_ADVERTISER, UserRole.KOC], //*广告主和KOC都可以访问这个路由
  '/advertising/partner': [UserRole.KOC_ADVERTISER],
  '/advertising/partner/detail': [UserRole.KOC_ADVERTISER],
  '/koc/mediaAccount': [UserRole.KOC],
  '/koc/orders': [UserRole.KOC],
  '/koc/tasks': [UserRole.KOC],
  '/koc/payment': [UserRole.KOC],
  '/koc/payment/detail': [UserRole.KOC],
  '/koc/info': [UserRole.KOC],
  '/koc/offers': [UserRole.KOC],
  '/koc/offers/detail': [UserRole.KOC],
  '/koc/offers/submission-history': [UserRole.KOC],
  '/koc/ugcTasks': [UserRole.KOC],
};

export const firstPage: Record<string, string> = {
  // *首次登录的时候传参，用于处理消息通知弹窗
  [UserRole.KOC_ADVERTISER]: '/advertising/promotion?firstLogin=true',
  [UserRole.KOC]: '/koc/offers?firstLogin=true',
};

export const TRIGGER_TYPES = {
  onSubmit: 'onSubmit',
  onBlur: 'onBlur',
  onChange: 'onChange',
};

export const REMEMBER_ACCOUNT_KEY = 'YOUDAOADS_ADVERTISE_ACCOUNT';
export const INVITE_ACCOUNT_KEY = 'YOUDAOADS_INVITE_ACCOUNT'; // 邀请页填的邮箱，用于注册页自动填充

/**
 * Youdaoads服务邮箱
 */
export const CONTACT_EMAIL = 'YDinfluencers@rd.netease.com';

export const MAX_PRODUCT_COUNT = 50;

export const dateFormat1 = 'YYYY_MM_DD';
export const dateFormat2 = 'YYYY.MM.DD';
export const dateFormat3 = 'YYYY/MM/DD';
