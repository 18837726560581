import { Locales } from '@/hooks/useLocal';
import { IndustryTypes } from './industryType';
import { zhFailImgBase64, enFailImgBase64, jaFailImgBase64 } from '@/constants/icons';

//除国家相关所有特殊处理的国际化
// ------ addLanguageNeedToEdit ------
export const errIntl: Record<Locales, Record<string, string>> = {
  'en-US': {
    role: 'Permission verification failed, please log in again',
    requestTimedOut: 'Request timed out',
    timedOut: 'Time out, please log in again.',
  },
  'zh-CN': {
    role: '权限校验失败，请重新登录',
    requestTimedOut: '请求超时',
    timedOut: '登录超时，请重新登录',
  },
  'ja-JP': {
    role: '権限検証に失敗しました。再度ログインしてください',
    requestTimedOut: 'リクエストがタイムアウトしました',
    timedOut: 'タイムアウトしました、再度ログインしてください',
  },
};

export const exportPromotionTraceIntl: Record<Locales, Record<string, string>> = {
  'zh-CN': {
    KOL名称: 'KOL名称',
    发布时间节点: '发布时间节点',
    曝光: '曝光',
    点赞: '点赞',
    评论: '评论',
    分享: '分享',
    收藏: '收藏',
    互动率: '互动率',
  },
  'en-US': {
    KOL名称: 'Influencer Name',
    发布时间节点: 'Launch Time Node',
    曝光: 'Impressions',
    点赞: 'Likes',
    评论: 'Comments',
    分享: 'Shares',
    收藏: 'Favorites',
    互动率: 'Engagement Rate',
  },
  'ja-JP': {
    KOL名称: 'KOL名',
    发布时间节点: '投稿時間ノード',
    曝光: '露出',
    点赞: 'いいね',
    评论: 'コメント',
    分享: 'シェア',
    收藏: 'お気に入り',
    互动率: 'エンゲージメント率',
  },
};

export const industryLabels: Record<Locales, Record<IndustryTypes, string>> = {
  'zh-CN': {
    [IndustryTypes.OTHER]: '其他',
    [IndustryTypes.ELECTRICAL]: '3C及电器',
    [IndustryTypes.FMCG]: '快速消费品',
    [IndustryTypes.FOOD_DRINK]: '食品饮料',
    [IndustryTypes.CLOTHING]: '服装配饰',
    [IndustryTypes.MEDICAL]: '医疗',
    [IndustryTypes.BUSINESS_SERVICES]: '商务服务',
    [IndustryTypes.LIFE_SERVICES]: '生活服务',
    [IndustryTypes.ESTATE]: '房地产',
    [IndustryTypes.HOME_BUILDING]: '家居建材',
    [IndustryTypes.EDUCATION]: '教育培训',
    [IndustryTypes.TRAVEL]: '出行旅游',
    [IndustryTypes.SOCIAL_PUBLIC]: '社会公共',
    [IndustryTypes.GAME]: '游戏',
    [IndustryTypes.RETAIL]: '零售',
    [IndustryTypes.TRANSPORTATION]: '交通工具',
    [IndustryTypes.CAR]: '汽车',
    [IndustryTypes.AGRICULTURE]: '农林牧畜渔',
    [IndustryTypes.CHEMICAL_ENERGY]: '化工及能源',
    [IndustryTypes.ELECTRONIC_ELECTRICAL]: '电子电工',
    [IndustryTypes.EQUIPMENT]: '机械设备',
    [IndustryTypes.ENTERTAINMENT]: '文娱娱乐',
    [IndustryTypes.MEDIA_CONTENT]: '传媒及内容',
    [IndustryTypes.LOGISTICS]: '物流业',
    [IndustryTypes.COMMUNICATION]: '通信',
    [IndustryTypes.FINANCIAL]: '金融业',
    [IndustryTypes.FOOD]: '餐饮服务',
    [IndustryTypes.TOOL_SOFTWARE]: '工具类软件',
    [IndustryTypes.JOIN]: '招商加盟',
  },
  'en-US': {
    [IndustryTypes.OTHER]: 'Others',
    [IndustryTypes.ELECTRICAL]: '3C & Electrical Appliances',
    [IndustryTypes.FMCG]: 'Fast-moving Consumer Goods',
    [IndustryTypes.FOOD_DRINK]: 'Food & Beverage',
    [IndustryTypes.CLOTHING]: 'Clothing & Accessories',
    [IndustryTypes.MEDICAL]: 'Medical Services',
    [IndustryTypes.BUSINESS_SERVICES]: 'Business Services',
    [IndustryTypes.LIFE_SERVICES]: 'Life Services',
    [IndustryTypes.ESTATE]: 'Real Estate',
    [IndustryTypes.HOME_BUILDING]: 'Home Building Materials',
    [IndustryTypes.EDUCATION]: 'Education & Training',
    [IndustryTypes.TRAVEL]: 'Travel',
    [IndustryTypes.SOCIAL_PUBLIC]: 'Social Public Services',
    [IndustryTypes.GAME]: 'Gaming',
    [IndustryTypes.RETAIL]: 'Retail',
    [IndustryTypes.TRANSPORTATION]: 'Transportation',
    [IndustryTypes.CAR]: 'Automotive',
    [IndustryTypes.AGRICULTURE]: 'Agriculture & Forestry & Fishing & Hunting',
    [IndustryTypes.CHEMICAL_ENERGY]: 'Chemical & Energy',
    [IndustryTypes.ELECTRONIC_ELECTRICAL]: 'Electronic Electrician',
    [IndustryTypes.EQUIPMENT]: 'Mechanical Equipment',
    [IndustryTypes.ENTERTAINMENT]: 'Cultural Entertainment',
    [IndustryTypes.MEDIA_CONTENT]: 'Media & Content',
    [IndustryTypes.LOGISTICS]: 'Logistics Industry',
    [IndustryTypes.COMMUNICATION]: 'Communications',
    [IndustryTypes.FINANCIAL]: 'Finance',
    [IndustryTypes.FOOD]: 'Food & Beverage Services',
    [IndustryTypes.TOOL_SOFTWARE]: 'Tool Software',
    [IndustryTypes.JOIN]: 'Investment',
  },
  'ja-JP': {
    [IndustryTypes.OTHER]: 'その他',
    [IndustryTypes.ELECTRICAL]: '3Cおよび電器',
    [IndustryTypes.FMCG]: 'FMCG',
    [IndustryTypes.FOOD_DRINK]: '食品飲料',
    [IndustryTypes.CLOTHING]: '衣類およびアクセサリー',
    [IndustryTypes.MEDICAL]: '医療',
    [IndustryTypes.BUSINESS_SERVICES]: 'ビジネスサービス',
    [IndustryTypes.LIFE_SERVICES]: '生活サービス',
    [IndustryTypes.ESTATE]: '不動産',
    [IndustryTypes.HOME_BUILDING]: '家庭建材',
    [IndustryTypes.EDUCATION]: '教育とトレーニング',
    [IndustryTypes.TRAVEL]: '旅行',
    [IndustryTypes.SOCIAL_PUBLIC]: '社会公共サービス',
    [IndustryTypes.GAME]: 'ゲーム',
    [IndustryTypes.RETAIL]: '小売',
    [IndustryTypes.TRANSPORTATION]: '交通手段',
    [IndustryTypes.CAR]: '自動車',
    [IndustryTypes.AGRICULTURE]: '農林漁牧',
    [IndustryTypes.CHEMICAL_ENERGY]: '化学エネルギー',
    [IndustryTypes.ELECTRONIC_ELECTRICAL]: '電子電気',
    [IndustryTypes.EQUIPMENT]: '機械装置',
    [IndustryTypes.ENTERTAINMENT]: 'エンターテイメント',
    [IndustryTypes.MEDIA_CONTENT]: 'メディアとコンテンツ',
    [IndustryTypes.LOGISTICS]: '物流業',
    [IndustryTypes.COMMUNICATION]: '通信',
    [IndustryTypes.FINANCIAL]: '金融',
    [IndustryTypes.FOOD]: '飲食サービス',
    [IndustryTypes.TOOL_SOFTWARE]: 'ツールソフトウェア',
    [IndustryTypes.JOIN]: '投資',
  },
};

export const fallImgSrc: Record<Locales, string> = {
  'zh-CN': zhFailImgBase64,
  'en-US': enFailImgBase64,
  'ja-JP': jaFailImgBase64,
};
