import { IntlProvider } from 'react-intl';
import { PropsWithChildren, useEffect } from 'react';
import { isString } from 'lodash';
import useLocale, { LOCALE_KEY, Locales } from '@/hooks/useLocal';
import { ConfigProvider } from 'antd';
import { useRouter } from 'next/router';
import theme from '@/theme/themeConfig';
import zhCN from 'antd/lib/locale/zh_CN';
import enUS from 'antd/lib/locale/en_US';
import jaJP from 'antd/lib/locale/ja_JP';

const antdLocale = {
  'en-US': enUS,
  'zh-CN': zhCN,
  'ja-JP': jaJP,
};

const languageMap: { [key: string]: Locales } = {
  zh: 'zh-CN',
  en: 'en-US',
  ja: 'ja-JP',
};

const onlyEnPath = ['/invite/offers'];

const Intl: React.FC<PropsWithChildren> = ({ children }) => {
  const { locale, messages, setLocale, locales } = useLocale();
  const { pathname } = useRouter();

  const Wrapper: React.FC<PropsWithChildren> = ({ children }) => <span className={locale}>{children}</span>;

  useEffect(() => {
    const initLocale = () => {
      const localLanguage = localStorage.getItem(LOCALE_KEY);
      if (isString(localLanguage) && locales.includes(localLanguage as Locales)) {
        return setLocale(localLanguage as Locales);
      }
      const browserLanguage = navigator.language;
      if (!browserLanguage) return;
      const matchedLocale = Object.keys(languageMap).find((key) => browserLanguage.includes(key));
      if (!matchedLocale) return;
      setLocale(languageMap[matchedLocale]);
    };

    initLocale();
  }, []);

  return (
    <IntlProvider
      locale={onlyEnPath.includes(pathname) ? 'en-US' : locale}
      messages={onlyEnPath.includes(pathname) ? messages['en-US'] : messages[locale]}
      textComponent={Wrapper as any}
      onError={() => null}
    >
      <ConfigProvider theme={theme} locale={antdLocale[locale]}>
        {children}
      </ConfigProvider>
    </IntlProvider>
  );
};

export default Intl;
