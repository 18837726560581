import { Locales } from '@/hooks/useLocal';

export enum IndustryTypes {
  OTHER, // 其他
  ELECTRICAL, // 3C及电器
  FMCG, // 快速消费品
  FOOD_DRINK, // 食品饮料
  CLOTHING, // 服装配饰
  MEDICAL, // 医疗
  BUSINESS_SERVICES, // 商务服务
  LIFE_SERVICES, // 生活服务
  ESTATE, // 房地产
  HOME_BUILDING, // 家居建材
  EDUCATION, // 教育培训
  TRAVEL, // 出行旅游
  SOCIAL_PUBLIC, // 社会公共
  GAME, // 游戏
  RETAIL, // 零售
  TRANSPORTATION, // 交通工具
  CAR, // 汽车
  AGRICULTURE, // 农林牧畜渔
  CHEMICAL_ENERGY, // 化工及能源
  ELECTRONIC_ELECTRICAL, // 电子电工
  EQUIPMENT, // 机械设备
  ENTERTAINMENT, // 文娱娱乐
  MEDIA_CONTENT, // 传媒及内容
  LOGISTICS, // 物流业
  COMMUNICATION, // 通信
  FINANCIAL, // 金融业
  FOOD, // 餐饮服务
  TOOL_SOFTWARE, // 工具类软件
  JOIN, // 招商加盟
}

// ------ addLanguageNeedToEdit ------

export const industryLabelSort = [
  IndustryTypes.GAME, // 游戏 ++
  IndustryTypes.ELECTRICAL, // 3C及电器
  IndustryTypes.FMCG, // 快速消费品
  IndustryTypes.FOOD_DRINK, // 食品饮料
  IndustryTypes.CLOTHING, // 服装配饰
  IndustryTypes.MEDICAL, // 医疗
  IndustryTypes.BUSINESS_SERVICES, // 商务服务
  IndustryTypes.LIFE_SERVICES, // 生活服务
  IndustryTypes.ESTATE, // 房地产
  IndustryTypes.HOME_BUILDING, // 家居建材
  IndustryTypes.EDUCATION, // 教育培训
  IndustryTypes.TRAVEL, // 出行旅游
  IndustryTypes.SOCIAL_PUBLIC, // 社会公共
  IndustryTypes.RETAIL, // 零售
  IndustryTypes.TRANSPORTATION, // 交通工具
  IndustryTypes.CAR, // 汽车
  IndustryTypes.AGRICULTURE, // 农林牧畜渔
  IndustryTypes.CHEMICAL_ENERGY, // 化工及能源
  IndustryTypes.ELECTRONIC_ELECTRICAL, // 电子电工
  IndustryTypes.EQUIPMENT, // 机械设备
  IndustryTypes.ENTERTAINMENT, // 文娱娱乐
  IndustryTypes.MEDIA_CONTENT, // 传媒及内容
  IndustryTypes.LOGISTICS, // 物流业
  IndustryTypes.COMMUNICATION, // 通信
  IndustryTypes.FINANCIAL, // 金融业
  IndustryTypes.FOOD, // 餐饮服务
  IndustryTypes.TOOL_SOFTWARE, // 工具类软件
  IndustryTypes.JOIN, // 招商加盟
  IndustryTypes.OTHER, // 其他 ++
];
