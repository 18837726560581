export const lgStyle = {
  areaCountrySelectWidth: {
    'zh-CN': 108,
    'en-US': 125,
    'ja-JP': 125,
  },
  expectedKolCountTotalWidth: {
    'zh-CN': 132,
    'en-US': 200,
    'ja-JP': 245,
  },
  confirmNumberWidth: {
    'zh-CN': 145,
    'en-US': 132,
    'ja-JP': 145,
  },
  rangePickerWidth: {
    'zh-CN': 86,
    'en-US': 108,
    'ja-JP': 86,
  },
  statueLabelWidth: {
    'zh-CN': 54,
    'en-US': 60,
    'ja-JP': 54,
  },
  notificationStyle: {
    'zh-CN': {},
    'en-US': { float: 'left' },
    'ja-JP': {},
  },
  transactionLabelWidth: {
    'zh-CN': '',
    'en-US': '180px',
    'ja-JP': '80px',
  },
  kocPaymentDetailWidth: {
    'zh-CN': 220,
    'en-US': 280,
    'ja-JP': 280,
  },
  kocOrderServiceTypeWidth: {
    'zh-CN': 220,
    'en-US': 240,
    'ja-JP': 240,
  },
  kocOrderSettledTypeWidth: {
    'zh-CN': 220,
    'en-US': 280,
    'ja-JP': 310,
  },
  kocOrderStatusWidth: {
    'zh-CN': 150,
    'en-US': 200,
    'ja-JP': 200,
  },
  kocTaskListServiceTypeWidth: {
    'zh-CN': 180,
    'en-US': 240,
    'ja-JP': 240,
  },
  kocTaskListSettledTypeWidth: {
    'zh-CN': 220,
    'en-US': 280,
    'ja-JP': 280,
  },
  kocTaskListStageWidth: {
    'zh-CN': 180,
    'en-US': 240,
    'ja-JP': 230,
  },
  kocTaskSearchPlatformWidth: {
    'zh-CN': 54,
    'en-US': 74,
    'ja-JP': 130,
  },
  kocTaskSearchStageWidth: {
    'zh-CN': 86,
    'en-US': 146,
    'ja-JP': 100,
  },
  partnerConfirmStyle: {
    'zh-CN': 'flex-start',
    'en-US': 'center',
    'ja-JP': 'flex-start',
  },
  partnerStatusWidth: {
    'zh-CN': 120,
    'en-US': 200,
    'ja-JP': 140,
  },
  partnerSearchWidth: {
    'zh-CN': 54,
    'en-US': 76,
    'ja-JP': 130,
  },
  partnerTableItemWidth: {
    'zh-CN': 220,
    'en-US': 370,
    'ja-JP': 220,
  },
  partnerTableSelectWidth: {
    'zh-CN': 86,
    'en-US': 146,
    'ja-JP': 86,
  },
  kocOffersContentTagIdsWidth: {
    'zh-CN': 52,
    'en-US': 82.67,
    'ja-JP': 62,
  },
  kocOffersRangeInputWidth: {
    'zh-CN': 94,
    'en-US': 84.38,
    'ja-JP': 130,
  },
  kocOffersLabelInSelectWidth: {
    'zh-CN': 80,
    'en-US': 71.06,
    'ja-JP': 82,
  },
  ugcKolNumber: {
    'zh-CN': 140,
    'en-US': 160,
    'ja-JP': 200,
  },
  ugcOnlineNumber: {
    'zh-CN': 140,
    'en-US': 160,
    'ja-JP': 160,
  },
  settledAmount: {
    'zh-CN': 180,
    'en-US': 180,
    'ja-JP': 220,
  },
};
