export const PromotionIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
    <path
      opacity="0.12"
      d="M10 18.3333C14.6024 18.3333 18.3334 14.6023 18.3334 9.99999C18.3334 5.39757 14.6024 1.66666 10 1.66666C5.3976 1.66666 1.66669 5.39757 1.66669 9.99999C1.66669 14.6023 5.3976 18.3333 10 18.3333Z"
      fill="currentColor"
    />
    <path
      d="M13.3334 9.99999L10 6.66666M10 6.66666L6.66669 9.99999M10 6.66666V14.3333C10 15.4922 10 16.0717 10.4588 16.7205C10.7636 17.1516 11.6412 17.6836 12.1644 17.7545C12.9518 17.8612 13.2508 17.7052 13.8489 17.3932C16.5139 16.003 18.3334 13.214 18.3334 9.99999C18.3334 5.39757 14.6024 1.66666 10 1.66666C5.3976 1.66666 1.66669 5.39757 1.66669 9.99999C1.66669 13.0845 3.34252 15.7776 5.83335 17.2185"
      stroke="currentColor"
      strokeWidth="1.66667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const PartnerIcon = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.88642 2.16731C10.6969 1.62698 11.7499 1.61578 12.5717 2.13874L15.979 4.30702H17.3802C17.8331 4.30702 18.2002 4.67415 18.2002 5.12703V12.5071C18.2002 12.96 17.8331 13.3271 17.3802 13.3271H16.1341C16.1828 14.1039 15.8169 14.8935 15.0807 15.3353L10.904 17.8413C10.3768 18.1576 9.72225 18.1502 9.20605 17.8351C8.6318 18.3429 7.74783 18.3988 7.10654 17.8949L2.89107 14.5828C2.26261 14.089 2.10368 13.2454 2.42819 12.5766C2.03187 12.2658 1.80023 11.79 1.80023 11.2861V5.12708C1.80023 4.6742 2.16737 4.30707 2.62024 4.30707H6.67676L9.88642 2.16731ZM3.58181 11.3962L4.14928 10.8997C5.15807 10.0169 6.68783 10.1044 7.5895 11.0962L9.80532 13.5336C10.5183 14.318 10.6484 15.4703 10.1298 16.3932L14.2369 13.929C14.4676 13.7905 14.5511 13.5138 14.4651 13.2807L10.8348 8.19843C10.6309 7.91294 10.268 7.78881 9.93193 7.88962L7.894 8.501C7.02717 8.76102 6.08755 8.52413 5.44762 7.88423L5.20755 7.64416C4.74177 7.17838 4.62132 6.5155 4.81733 5.94708H3.44025V11.2861L3.58181 11.3962ZM11.6913 3.52237C11.4173 3.34804 11.0663 3.35177 10.7961 3.53189L6.36721 6.48449L6.60728 6.72457C6.82059 6.93788 7.1338 7.01683 7.42275 6.93015L9.46067 6.31877C10.4688 6.01633 11.5577 6.38873 12.1694 7.24518L15.3422 11.6871H16.5602V5.94704H15.979C15.6671 5.94704 15.3616 5.8581 15.0985 5.69064L11.6913 3.52237ZM5.22924 12.1339L3.90432 13.2932L8.11978 16.6054L8.69699 15.5952C8.87329 15.2867 8.83082 14.8998 8.59179 14.6368L6.37598 12.1994C6.07543 11.8688 5.5655 11.8397 5.22924 12.1339Z"
      fill="currentColor"
    />
    <path
      opacity="0.12"
      d="M3.58187 11.3963L4.14934 10.8998C5.15813 10.0171 6.68788 10.1045 7.58956 11.0964L9.80538 13.5338C10.5184 14.3181 10.6484 15.4704 10.1299 16.3933L14.2369 13.9291C14.4677 13.7907 14.5512 13.5139 14.4652 13.2808L10.8349 8.19854C10.631 7.91306 10.268 7.78893 9.93199 7.88974L7.89406 8.50112C7.02723 8.76114 6.08761 8.52424 5.44768 7.88435L5.20761 7.64428C4.74183 7.1785 4.62138 6.51562 4.81739 5.9472H3.44031V11.2862L3.58187 11.3963ZM11.6913 3.52249C11.4173 3.34816 11.0664 3.35189 10.7962 3.53201L6.36727 6.48461L6.60734 6.72469C6.82065 6.938 7.13386 7.01695 7.42281 6.93027L9.46073 6.31889C10.4688 6.01645 11.5577 6.38885 12.1695 7.2453L15.3422 11.6872H16.5603V5.94715H15.9791C15.6671 5.94715 15.3617 5.85822 15.0985 5.69076L11.6913 3.52249ZM5.2293 12.134L3.90437 13.2933L8.11984 16.6055L8.69705 15.5953C8.87335 15.2869 8.83088 14.8999 8.59185 14.6369L6.37604 12.1995C6.07549 11.8689 5.56556 11.8398 5.2293 12.134Z"
      fill="currentColor"
    />
  </svg>
);

export const AccountIcon = () => (
  <svg width="19" height="17" viewBox="0 0 19 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="Frame 427319472">
      <path
        id="Vector"
        opacity="0.12"
        d="M7.20506 8.5C9.27614 8.5 10.9551 6.82108 10.9551 4.75C10.9551 2.67892 9.27614 1 7.20506 1C5.13398 1 3.45506 2.67892 3.45506 4.75C3.45506 6.82108 5.13406 8.5 7.20506 8.5ZM7.20506 11C4.84623 11 2.74973 12.2872 1.42114 14.2819C1.13006 14.719 0.984478 14.9375 1.00131 15.2166C1.01431 15.4339 1.15681 15.7 1.33048 15.8313C1.55348 16 1.86023 16 2.47373 16H11.9365C12.55 16 12.8567 16 13.0797 15.8313C13.2534 15.7 13.3958 15.4339 13.4089 15.2166C13.4256 14.9375 13.2801 14.719 12.9891 14.2819C11.6605 12.2872 9.56398 11 7.20506 11Z"
        fill="currentColor"
      />
      <path
        id="Vector_2"
        d="M14.2885 11.6974C15.5017 12.3069 16.542 13.285 17.3012 14.508C17.4515 14.7503 17.5267 14.8713 17.5527 15.039C17.6055 15.3798 17.3725 15.7988 17.0551 15.9337C16.8989 16 16.7232 16 16.3718 16M12.6218 8.11017C13.8566 7.49658 14.7051 6.22242 14.7051 4.75C14.7051 3.27758 13.8566 2.00342 12.6218 1.38983M10.9551 4.75C10.9551 6.82108 9.2762 8.5 7.20512 8.5C5.13404 8.5 3.45512 6.82108 3.45512 4.75C3.45512 2.67892 5.13412 1 7.20512 1C9.2762 1 10.9551 2.67892 10.9551 4.75ZM1.4212 14.2819C2.74979 12.2872 4.84629 11 7.20512 11C9.56404 11 11.6605 12.2872 12.9891 14.2819C13.2802 14.719 13.4257 14.9375 13.409 15.2166C13.3959 15.4339 13.2535 15.7 13.0798 15.8313C12.8568 16 12.55 16 11.9365 16H2.47379C1.86029 16 1.55354 16 1.33045 15.8313C1.15687 15.7 1.01437 15.4339 1.00129 15.2166C0.98462 14.9375 1.13012 14.719 1.4212 14.2819Z"
        stroke="currentColor"
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);

export const OrdersIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
    <path
      d="M2.49984 2.5H17.4998C17.9601 2.5 18.3332 2.8731 18.3332 3.33333V16.6667C18.3332 17.1269 17.9601 17.5 17.4998 17.5H2.49984C2.0396 17.5 1.6665 17.1269 1.6665 16.6667V3.33333C1.6665 2.8731 2.0396 2.5 2.49984 2.5Z"
      stroke="currentColor"
      strokeWidth="1.66667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      opacity="0.12"
      d="M2.49984 2.5H17.4998C17.9601 2.5 18.3332 2.8731 18.3332 3.33333V16.6667C18.3332 17.1269 17.9601 17.5 17.4998 17.5H2.49984C2.0396 17.5 1.6665 17.1269 1.6665 16.6667V3.33333C1.6665 2.8731 2.0396 2.5 2.49984 2.5Z"
      fill="currentColor"
    />
    <path
      d="M10.3744 9.45529C10.1879 9.61668 9.9116 9.61798 9.7236 9.45836L5.34109 5.73744C4.99025 5.43957 4.46436 5.4825 4.16649 5.83334C3.86861 6.18418 3.91155 6.71006 4.26239 7.00794L9.73402 11.6535C9.92202 11.8131 10.1983 11.8118 10.3848 11.6505L15.7483 7.00881C16.0964 6.70763 16.1343 6.18135 15.8331 5.83333C15.532 5.48532 15.0057 5.44735 14.6577 5.74853L10.3744 9.45529Z"
      fill="currentColor"
    />
  </svg>
);

export const TransactionIcon = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="Frame">
      <path
        id="Vector"
        opacity="0.12"
        d="M3.33331 6.5C3.33331 5.09983 3.33331 4.39983 3.60581 3.865C3.84549 3.39462 4.22793 3.01218 4.69831 2.7725C5.23315 2.5 5.93315 2.5 7.33331 2.5H12.6666C14.0668 2.5 14.7668 2.5 15.3016 2.7725C15.772 3.01217 16.1545 3.39461 16.3941 3.865C16.6666 4.39983 16.6666 5.09983 16.6666 6.5V17.5L14.375 15.8333L12.2916 17.5L9.99998 15.8333L7.70831 17.5L5.62498 15.8333L3.33331 17.5V6.5Z"
        fill="currentColor"
      />
      <path
        id="Vector_2"
        d="M7.49998 8.75L9.16665 10.4167L12.9166 6.66667M16.6666 17.5V6.5C16.6666 5.09983 16.6666 4.39983 16.3941 3.865C16.1545 3.39461 15.772 3.01217 15.3016 2.7725C14.7668 2.5 14.0668 2.5 12.6666 2.5H7.33331C5.93315 2.5 5.23315 2.5 4.69831 2.7725C4.22793 3.01218 3.84549 3.39462 3.60581 3.865C3.33331 4.39983 3.33331 5.09983 3.33331 6.5V17.5L5.62498 15.8333L7.70831 17.5L9.99998 15.8333L12.2916 17.5L14.375 15.8333L16.6666 17.5Z"
        stroke="currentColor"
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);
export const NotificationIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="16" viewBox="0 0 18 16" fill="none">
    <path
      opacity="0.12"
      d="M16.5 2.5C16.5 1.67157 15.8284 1 15 1H3C2.17157 1 1.5 1.67157 1.5 2.5V11.5C1.5 12.3284 2.17157 13 3 13H6.8175C7.01426 13.0008 7.2028 13.0789 7.3425 13.2175L8.46 14.335C8.56539 14.4405 8.70837 14.5 8.8575 14.5H9.1425C9.29163 14.5 9.43461 14.4405 9.54 14.335L10.6575 13.2175C10.7972 13.0789 10.9857 13.0008 11.1825 13H15C15.8284 13 16.5 12.3284 16.5 11.5V2.5Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.99984 0.166687C1.71117 0.166687 0.666504 1.21136 0.666504 2.50002V11.5C0.666504 12.7887 1.71117 13.8334 2.99984 13.8334H6.77966L7.87021 14.9239L7.87058 14.9243L8.45984 14.335L7.87021 14.9239C8.13136 15.1854 8.48623 15.3334 8.85734 15.3334H9.14234C9.51327 15.3334 9.86798 15.1855 10.1291 14.9243L10.1295 14.9239L11.22 13.8334H14.9998C16.2885 13.8334 17.3332 12.7887 17.3332 11.5V2.50002C17.3332 1.21136 16.2885 0.166687 14.9998 0.166687H2.99984ZM6.7555 13.8092L6.77966 13.8334H6.81734V13.7468L7.34234 13.2175L6.81734 13.7425V13L6.8142 13.7457L6.75308 13.8068L6.7555 13.8092ZM6.7555 13.8092C6.77102 13.8246 6.79197 13.8333 6.81383 13.8333L6.81418 13.75L6.7555 13.8092ZM2.33317 2.50002C2.33317 2.13183 2.63165 1.83335 2.99984 1.83335H14.9998C15.368 1.83335 15.6665 2.13183 15.6665 2.50002V11.5C15.6665 11.8682 15.368 12.1667 14.9998 12.1667L11.1823 12.1667L11.1788 12.1667C10.7635 12.1684 10.3654 12.3334 10.0705 12.6259L10.0681 12.6283L9.02966 13.6667H8.97001L7.9316 12.6283L7.92918 12.6259C7.63427 12.3334 7.23622 12.1684 6.82085 12.1667H6.81734H2.99984C2.63165 12.1667 2.33317 11.8682 2.33317 11.5V2.50002ZM13.4998 5.95002C13.4998 6.17093 13.3319 6.35002 13.1248 6.35002H4.87484C4.66773 6.35002 4.49984 6.17093 4.49984 5.95002V5.15002C4.49984 4.92911 4.66773 4.75002 4.87484 4.75002H13.1248C13.3319 4.75002 13.4998 4.92911 13.4998 5.15002V5.95002ZM10.4998 8.87502C10.4998 9.08213 10.3319 9.25002 10.1248 9.25002H4.87481C4.6677 9.25002 4.49981 9.08213 4.49981 8.87502V8.12502C4.49981 7.91791 4.6677 7.75002 4.87481 7.75002H10.1248C10.3319 7.75002 10.4998 7.91791 10.4998 8.12502V8.87502Z"
      fill="currentColor"
    />
  </svg>
);

export const KocTaskIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
    <g clipPath="url(#clip0_1302_9691)">
      <path
        opacity="0.12"
        d="M1.6665 9.33366C1.6665 8.40024 1.6665 7.93349 1.84817 7.57699C2.00795 7.2634 2.26291 7.00844 2.5765 6.84866C2.933 6.66699 3.39984 6.66699 4.33317 6.66699H10.6665C11.5999 6.66699 12.0667 6.66699 12.4232 6.84866C12.7368 7.00841 12.9918 7.26341 13.1515 7.57699C13.3332 7.93349 13.3332 8.40033 13.3332 9.33366V15.667C13.3332 16.6004 13.3332 17.0672 13.1515 17.4237C12.9917 17.7373 12.7368 17.9922 12.4232 18.152C12.0667 18.3337 11.5999 18.3337 10.6665 18.3337H4.33317C3.39975 18.3337 2.933 18.3337 2.5765 18.152C2.2629 17.9922 2.00794 17.7373 1.84817 17.4237C1.6665 17.0672 1.6665 16.6004 1.6665 15.667V9.33366Z"
        fill="currentColor"
      />
      <path
        d="M4.99984 12.5003L6.6665 14.167L10.4165 10.417M6.6665 6.66699V4.33366C6.6665 3.40024 6.6665 2.93349 6.84817 2.57699C7.00795 2.2634 7.26291 2.00844 7.5765 1.84866C7.933 1.66699 8.39984 1.66699 9.33317 1.66699H15.6665C16.5999 1.66699 17.0667 1.66699 17.4232 1.84866C17.7368 2.00841 17.9918 2.26341 18.1515 2.57699C18.3332 2.93349 18.3332 3.40033 18.3332 4.33366V10.667C18.3332 11.6004 18.3332 12.0672 18.1515 12.4237C17.9917 12.7373 17.7368 12.9922 17.4232 13.152C17.0667 13.3337 16.5999 13.3337 15.6665 13.3337H13.3332M4.33317 18.3337H10.6665C11.5999 18.3337 12.0667 18.3337 12.4232 18.152C12.7368 17.9922 12.9917 17.7373 13.1515 17.4237C13.3332 17.0672 13.3332 16.6004 13.3332 15.667V9.33366C13.3332 8.40024 13.3332 7.93349 13.1515 7.57699C12.9917 7.26339 12.7368 7.00843 12.4232 6.84866C12.0667 6.66699 11.5999 6.66699 10.6665 6.66699H4.33317C3.39975 6.66699 2.933 6.66699 2.5765 6.84866C2.26291 7.00844 2.00795 7.2634 1.84817 7.57699C1.6665 7.93349 1.6665 8.40033 1.6665 9.33366V15.667C1.6665 16.6004 1.6665 17.0672 1.84817 17.4237C2.00792 17.7372 2.26292 17.9922 2.5765 18.152C2.933 18.3337 3.39984 18.3337 4.33317 18.3337Z"
        stroke="currentColor"
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_1302_9691">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export const PaymentIcon = () => (
  <svg width="18" height="17" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="Frame 427319841">
      <path
        id="Vector"
        opacity="0.12"
        d="M15.2222 15.5758H2.77778C1.79594 15.5758 1 14.7799 1 13.798V5.79804C1 4.8162 1.79594 4.02026 2.77778 4.02026H15.2222C16.2041 4.02026 17 4.8162 17 5.79804V13.798C17 14.7799 16.2041 15.5758 15.2222 15.5758Z"
        fill="currentColor"
      />
      <path
        id="Vector_2"
        d="M15.2222 15.5758H2.77778C1.79594 15.5758 1 14.7799 1 13.798V5.79804C1 4.8162 1.79594 4.02026 2.77778 4.02026H15.2222C16.2041 4.02026 17 4.8162 17 5.79804V13.798C17 14.7799 16.2041 15.5758 15.2222 15.5758Z"
        stroke="currentColor"
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="Vector_3"
        d="M13.0001 10.2426C12.7547 10.2426 12.5557 10.0436 12.5557 9.7982C12.5557 9.55278 12.7547 9.35376 13.0001 9.35376C13.2455 9.35376 13.4446 9.55278 13.4446 9.7982C13.4446 10.0436 13.2455 10.2426 13.0001 10.2426Z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="Vector_4"
        d="M14.3333 4.02033V2.77875C14.3333 1.61069 13.2261 0.760036 12.0975 1.061L2.31971 3.66841C1.54148 3.87594 1 4.58074 1 5.38616V5.79811"
        stroke="currentColor"
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);
export const KocOffersIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
    <path
      d="M2.50008 1.5C2.03985 1.5 1.66675 1.8731 1.66675 2.33333V8.33333C1.66675 8.79358 2.03985 9.16667 2.50008 9.16667H8.33341C8.79366 9.16667 9.16675 8.79358 9.16675 8.33333V2.33333C9.16675 1.8731 8.79366 1.5 8.33341 1.5H2.50008ZM3.33341 7.5V3.16667H7.50008V7.5H3.33341ZM2.50008 10.8333C2.03985 10.8333 1.66675 11.2064 1.66675 11.6667V17.6667C1.66675 18.1269 2.03985 18.5 2.50008 18.5H8.33341C8.79366 18.5 9.16675 18.1269 9.16675 17.6667V11.6667C9.16675 11.2064 8.79366 10.8333 8.33341 10.8333H2.50008ZM3.33341 16.8333V12.5H7.50008V16.8333H3.33341ZM10.8334 2.33333C10.8334 1.8731 11.2065 1.5 11.6667 1.5H17.5001C17.9603 1.5 18.3334 1.8731 18.3334 2.33333V8.33333C18.3334 8.79358 17.9603 9.16667 17.5001 9.16667H11.6667C11.2065 9.16667 10.8334 8.79358 10.8334 8.33333V2.33333ZM12.5001 3.16667V7.5H16.6667V3.16667H12.5001ZM11.6667 10.8333C11.2065 10.8333 10.8334 11.2064 10.8334 11.6667V17.6667C10.8334 18.1269 11.2065 18.5 11.6667 18.5H17.5001C17.9603 18.5 18.3334 18.1269 18.3334 17.6667V11.6667C18.3334 11.2064 17.9603 10.8333 17.5001 10.8333H11.6667ZM12.5001 16.8333V12.5H16.6667V16.8333H12.5001Z"
      fill="currentColor"
    />
    <path
      opacity="0.12"
      d="M3.33325 7.50002V3.16669H7.49992V7.50002H3.33325ZM3.33325 16.8334V12.5H7.49992V16.8334H3.33325ZM12.4999 3.16669V7.50002H16.6666V3.16669H12.4999ZM12.4999 16.8334V12.5H16.6666V16.8334H12.4999Z"
      fill="currentColor"
    />
  </svg>
);

export const KocOrdersIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
    <path d="M14.1966 7.00936L10 10.206L5.80339 7.00936" stroke="currentColor" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M3.025 3H16.975C17.403 3 17.75 3.34823 17.75 3.77778V16.2222C17.75 16.6518 17.403 17 16.975 17H3.025C2.59698 17 2.25 16.6518 2.25 16.2222V3.77778C2.25 3.34823 2.59698 3 3.025 3Z"
      fill="currentColor"
      fillOpacity="0.12"
      stroke="currentColor"
      strokeWidth="1.66667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const KocProjectIcon = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_7188_1218)">
      <g clipPath="url(#clip1_7188_1218)">
        <path
          opacity="0.12"
          d="M6.6665 9.33366C6.6665 8.40024 6.6665 7.93349 6.84817 7.57699C7.00795 7.2634 7.26291 7.00844 7.5765 6.84866C7.933 6.66699 8.39984 6.66699 9.33317 6.66699H15.6665C16.5999 6.66699 17.0667 6.66699 17.4232 6.84866C17.7368 7.00841 17.9918 7.26341 18.1515 7.57699C18.3332 7.93349 18.3332 8.40033 18.3332 9.33366V15.667C18.3332 16.6004 18.3332 17.0672 18.1515 17.4237C17.9917 17.7373 17.7368 17.9922 17.4232 18.152C17.0667 18.3337 16.5999 18.3337 15.6665 18.3337H9.33317C8.39975 18.3337 7.933 18.3337 7.5765 18.152C7.2629 17.9922 7.00794 17.7373 6.84817 17.4237C6.6665 17.0672 6.6665 16.6004 6.6665 15.667V9.33366Z"
          fill="currentColor"
        />
        <path
          d="M13.3333 6.66699V4.33366C13.3333 3.40024 13.3333 2.93349 13.1516 2.57699C12.9918 2.2634 12.7368 2.00844 12.4233 1.84866C12.0668 1.66699 11.5999 1.66699 10.6666 1.66699H4.33325C3.39984 1.66699 2.93308 1.66699 2.57658 1.84866C2.263 2.00841 2.008 2.26341 1.84825 2.57699C1.66659 2.93349 1.66659 3.40033 1.66659 4.33366V10.667C1.66659 11.6004 1.66659 12.0672 1.84825 12.4237C2.00802 12.7373 2.26298 12.9922 2.57658 13.152C2.93308 13.3337 3.39984 13.3337 4.33325 13.3337H6.66659M15.6666 18.3337H9.33325C8.39984 18.3337 7.93309 18.3337 7.57659 18.152C7.26298 17.9922 7.00802 17.7373 6.84825 17.4237C6.66659 17.0672 6.66659 16.6004 6.66659 15.667V9.33366C6.66659 8.40024 6.66659 7.93349 6.84825 7.57699C7.00802 7.26339 7.26299 7.00843 7.57659 6.84866C7.93309 6.66699 8.39984 6.66699 9.33325 6.66699H15.6666C16.6 6.66699 17.0668 6.66699 17.4233 6.84866C17.7368 7.00844 17.9918 7.2634 18.1516 7.57699C18.3333 7.93349 18.3333 8.40033 18.3333 9.33366V15.667C18.3333 16.6004 18.3333 17.0672 18.1516 17.4237C17.9918 17.7372 17.7368 17.9922 17.4233 18.152C17.0668 18.3337 16.5999 18.3337 15.6666 18.3337Z"
          stroke="currentColor"
          strokeWidth="1.66667"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10 12.5003L11.6667 14.167L15.4167 10.417"
          stroke="currentColor"
          strokeWidth="1.66667"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_7188_1218">
        <rect width="20" height="20" fill="white" />
      </clipPath>
      <clipPath id="clip1_7188_1218">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export const KocMediaAccountIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="19" height="17" viewBox="0 0 19 17" fill="none">
    <path
      opacity="0.12"
      d="M7.20506 8.5C9.27614 8.5 10.9551 6.82108 10.9551 4.75C10.9551 2.67892 9.27614 1 7.20506 1C5.13398 1 3.45506 2.67892 3.45506 4.75C3.45506 6.82108 5.13406 8.5 7.20506 8.5ZM7.20506 11C4.84623 11 2.74973 12.2872 1.42114 14.2819C1.13006 14.719 0.984478 14.9375 1.00131 15.2166C1.01431 15.4339 1.15681 15.7 1.33048 15.8313C1.55348 16 1.86023 16 2.47373 16H11.9365C12.55 16 12.8567 16 13.0797 15.8313C13.2534 15.7 13.3958 15.4339 13.4089 15.2166C13.4256 14.9375 13.2801 14.719 12.9891 14.2819C11.6605 12.2872 9.56398 11 7.20506 11Z"
      fill="currentColor"
    />
    <path
      d="M14.2885 11.6974C15.5017 12.3069 16.542 13.285 17.3012 14.508C17.4515 14.7503 17.5267 14.8713 17.5527 15.039C17.6055 15.3798 17.3725 15.7988 17.0551 15.9337C16.8989 16 16.7232 16 16.3718 16M12.6218 8.11017C13.8566 7.49658 14.7051 6.22242 14.7051 4.75C14.7051 3.27758 13.8566 2.00342 12.6218 1.38983M10.9551 4.75C10.9551 6.82108 9.2762 8.5 7.20512 8.5C5.13404 8.5 3.45512 6.82108 3.45512 4.75C3.45512 2.67892 5.13412 1 7.20512 1C9.2762 1 10.9551 2.67892 10.9551 4.75ZM1.4212 14.2819C2.74979 12.2872 4.84629 11 7.20512 11C9.56404 11 11.6605 12.2872 12.9891 14.2819C13.2802 14.719 13.4257 14.9375 13.409 15.2166C13.3959 15.4339 13.2535 15.7 13.0798 15.8313C12.8568 16 12.55 16 11.9365 16H2.47379C1.86029 16 1.55354 16 1.33045 15.8313C1.15687 15.7 1.01437 15.4339 1.00129 15.2166C0.98462 14.9375 1.13012 14.719 1.4212 14.2819Z"
      stroke="currentColor"
      strokeWidth="1.66667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const MyPaymentIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="17" viewBox="0 0 18 17" fill="none">
    <path
      opacity="0.12"
      d="M15.2222 15.5758H2.77778C1.79594 15.5758 1 14.7799 1 13.798V5.79804C1 4.8162 1.79594 4.02026 2.77778 4.02026H15.2222C16.2041 4.02026 17 4.8162 17 5.79804V13.798C17 14.7799 16.2041 15.5758 15.2222 15.5758Z"
      fill="currentColor"
    />
    <path
      d="M15.2222 15.5758H2.77778C1.79594 15.5758 1 14.7799 1 13.798V5.79804C1 4.8162 1.79594 4.02026 2.77778 4.02026H15.2222C16.2041 4.02026 17 4.8162 17 5.79804V13.798C17 14.7799 16.2041 15.5758 15.2222 15.5758Z"
      stroke="currentColor"
      strokeWidth="1.66667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.9999 10.2425C12.7544 10.2425 12.5554 10.0435 12.5554 9.79808C12.5554 9.55266 12.7544 9.35364 12.9999 9.35364C13.2453 9.35364 13.4443 9.55266 13.4443 9.79808C13.4443 10.0435 13.2453 10.2425 12.9999 10.2425Z"
      fill="currentColor"
      stroke="currentColor"
      strokeWidth="1.66667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.3333 4.02033V2.77875C14.3333 1.61069 13.2261 0.760036 12.0975 1.061L2.31971 3.66841C1.54148 3.87594 1 4.58074 1 5.38616V5.79811"
      stroke="currentColor"
      strokeWidth="1.66667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const MyNotificationIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="16" viewBox="0 0 18 16" fill="none">
    <path
      opacity="0.12"
      d="M16.5 2.5C16.5 1.67157 15.8284 1 15 1H3C2.17157 1 1.5 1.67157 1.5 2.5V11.5C1.5 12.3284 2.17157 13 3 13H6.8175C7.01426 13.0008 7.2028 13.0789 7.3425 13.2175L8.46 14.335C8.56539 14.4405 8.70837 14.5 8.8575 14.5H9.1425C9.29163 14.5 9.43461 14.4405 9.54 14.335L10.6575 13.2175C10.7972 13.0789 10.9857 13.0008 11.1825 13H15C15.8284 13 16.5 12.3284 16.5 11.5V2.5Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.00008 0.166626C1.71142 0.166626 0.666748 1.21129 0.666748 2.49996V11.5C0.666748 12.7886 1.71142 13.8333 3.00008 13.8333H6.7799L7.87046 14.9238L7.87083 14.9242L8.46008 14.335L7.87046 14.9238C8.13161 15.1853 8.48648 15.3333 8.85758 15.3333H9.14258C9.51351 15.3333 9.86822 15.1855 10.1293 14.9242L10.1297 14.9238L11.2203 13.8333H15.0001C16.2887 13.8333 17.3334 12.7886 17.3334 11.5V2.49996C17.3334 1.21129 16.2887 0.166626 15.0001 0.166626H3.00008ZM6.75574 13.8091L6.7799 13.8333H6.81758V13.7468L7.34258 13.2175L6.81758 13.7425V13L6.81444 13.7456L6.75333 13.8067L6.75574 13.8091ZM6.75574 13.8091C6.77126 13.8245 6.79221 13.8332 6.81407 13.8333L6.81442 13.75L6.75574 13.8091ZM2.33341 2.49996C2.33341 2.13177 2.63189 1.83329 3.00008 1.83329H15.0001C15.3683 1.83329 15.6667 2.13177 15.6667 2.49996V11.5C15.6667 11.8681 15.3683 12.1666 15.0001 12.1666L11.1826 12.1666L11.1791 12.1666C10.7637 12.1684 10.3657 12.3333 10.0707 12.6258L10.0683 12.6282L9.0299 13.6666H8.97026L7.93184 12.6282L7.92942 12.6258C7.63451 12.3333 7.23646 12.1684 6.82109 12.1666H6.81758H3.00008C2.63189 12.1666 2.33341 11.8681 2.33341 11.5V2.49996ZM13.5001 5.94996C13.5001 6.17087 13.3322 6.34996 13.1251 6.34996H4.87508C4.66797 6.34996 4.50008 6.17087 4.50008 5.94996V5.14996C4.50008 4.92905 4.66797 4.74996 4.87508 4.74996H13.1251C13.3322 4.74996 13.5001 4.92905 13.5001 5.14996V5.94996ZM10.5001 8.87496C10.5001 9.08207 10.3322 9.24996 10.1251 9.24996H4.87508C4.66797 9.24996 4.50008 9.08207 4.50008 8.87496V8.12496C4.50008 7.91785 4.66797 7.74996 4.87508 7.74996H10.1251C10.3322 7.74996 10.5001 7.91785 10.5001 8.12496V8.87496Z"
      fill="currentColor"
    />
  </svg>
);
