import { AllPlatforms } from '@/constants/platform';
import Icon, { IconType } from '../platformIcon';
import { FC } from 'react';
import styles from './styles/PlatformAccount.module.scss';
import { Avatar, Typography } from 'antd';
import { UserOutlined } from '@ant-design/icons';

const { Text } = Typography;
interface Props {
  platform: AllPlatforms;
  kolname: string;
  homePageUrl: string;
  avatarUrl: string;
  style?: React.CSSProperties;
  textStyle?: React.CSSProperties;
}

const PlatformAccount: FC<Props> = ({ platform, kolname, homePageUrl, avatarUrl, style, textStyle = {} }) => {
  return (
    <div
      className={styles.content}
      style={style}
      onClick={(e) => {
        e.stopPropagation();
        window && window.open(homePageUrl, '_blank');
      }}
    >
      <div className={styles.imgs}>
        <Avatar className={styles.avatar} src={avatarUrl} size={20} icon={<UserOutlined />} />
        <div className={styles.platformIcon}>
          <Icon type={`${platform.toLowerCase()}Icon` as IconType} />
        </div>
      </div>
      <Text ellipsis style={textStyle}>
        {kolname}
      </Text>
    </div>
  );
};
export default PlatformAccount;
