import { useIntl } from 'react-intl';
import { lgStyle } from '@/constants/lgStyle';

export type localeType = 'zh-CN' | 'en-US' | 'ja-JP';
export type apiTagNameType = 'name' | 'nameEn' | 'nameJa';

// 单纯的key value 对应表
export const lg: any = {
  ...lgStyle,
  apiTagName: {
    'zh-CN': 'name',
    'en-US': 'nameEn',
    'ja-JP': 'nameJa',
  },
  areaCountryOneMap: {
    'zh-CN': 'areaName',
    'en-US': 'areaEnglishName',
    'ja-JP': 'areaJaName',
  },
  areaCountryTwoMap: {
    'zh-CN': 'chineseName',
    'en-US': 'englishName',
    'ja-JP': 'jaName',
  },
  languageCodeMap: {
    'zh-CN': 'label',
    'en-US': 'labelEn',
    'ja-JP': 'labelJa',
  },
  areaCountrySymbol: {
    'zh-CN': '，',
    'en-US': ', ',
    'ja-JP': ', ',
  },
  areaCountryOther: {
    'zh-CN': '其他',
    'en-US': 'Others',
    'ja-JP': 'その他',
  },
  kocOffersDetailGrid: {
    'zh-CN': 12,
    'en-US': 20,
    'ja-JP': 20,
  },
};

const useLanguage = () => {
  const { locale } = useIntl();
  const typedLocale = locale as localeType;

  // 根据key获取对应的语言文本
  const getLg = (key: string, lang?: localeType) => {
    if (!lg[key]) return key;
    const lgType = lang || typedLocale;
    if (lg?.[key]?.[lgType || 'en-US']) return lg?.[key]?.[lgType || 'en-US'];
  };

  return {
    lg,
    getLg,
  };
};

export default useLanguage;
