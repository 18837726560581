import { lg } from '@/hooks/useLanguage';
// ------ addLanguageNeedToEdit ------
export interface CountryPropsLgName {
  chineseName: string;
  englishName: string;
  jaName: string;
}
export interface CountryProps extends CountryPropsLgName {
  countryId: number;
  twoLetterCode: string;
  phoneCode?: string;
}

export interface AreaLgName {
  areaName: string;
  areaEnglishName: string;
  areaJaName: string;
}
interface Area extends AreaLgName {
  countryList: CountryProps[];
}

export const areaCountryList: Area[] = [
  {
    areaName: '东南亚',
    areaEnglishName: 'Southeast Asia',
    areaJaName: '東南アジア',
    countryList: [
      {
        countryId: 106,
        chineseName: '菲律宾',
        englishName: 'Philippines',
        jaName: 'フィリピン',
        twoLetterCode: 'PH',
        phoneCode: '+63',
      },
      {
        countryId: 102,
        chineseName: '马来西亚',
        englishName: 'Malaysia',
        jaName: 'マレーシア',
        twoLetterCode: 'MY',
        phoneCode: '+60',
      },
      {
        countryId: 108,
        chineseName: '泰国',
        englishName: 'Thailand',
        jaName: 'タイ',
        twoLetterCode: 'TH',
        phoneCode: '+66',
      },
      {
        countryId: 110,
        chineseName: '新加坡',
        englishName: 'Singapore',
        jaName: 'シンガポール',
        twoLetterCode: 'SG',
        phoneCode: '+65',
      },
      {
        countryId: 100,
        chineseName: '印度尼西亚',
        englishName: 'Indonesia',
        jaName: 'インドネシア',
        twoLetterCode: 'ID',
        phoneCode: '+62',
      },
      {
        countryId: 104,
        chineseName: '越南',
        englishName: 'Vietnam',
        jaName: 'ベトナム',
        twoLetterCode: 'VN',
        phoneCode: '+84',
      },
      {
        countryId: 120,
        chineseName: '东帝汶',
        englishName: 'Timor-Leste',
        jaName: '東ティモール',
        twoLetterCode: 'TL',
        phoneCode: '+670',
      },
      {
        countryId: 114,
        chineseName: '柬埔寨',
        englishName: 'Cambodia',
        jaName: 'カンボジア',
        twoLetterCode: 'KH',
        phoneCode: '+855',
      },
      {
        countryId: 112,
        chineseName: '老挝',
        englishName: 'Laos',
        jaName: 'ラオス',
        twoLetterCode: 'LA',
        phoneCode: '+856',
      },
      {
        countryId: 116,
        chineseName: '缅甸',
        englishName: 'Myanmar (Burma)',
        jaName: 'ミャンマー',
        twoLetterCode: 'MM',
        phoneCode: '+95',
      },
      {
        countryId: 118,
        chineseName: '文莱',
        englishName: 'Brunei',
        jaName: 'ブルネイ',
        twoLetterCode: 'BN',
        phoneCode: '+673',
      },
    ],
  },
  {
    areaName: '澳洲',
    areaEnglishName: 'Australia',
    areaJaName: 'オーストラリア',
    countryList: [
      {
        countryId: 502,
        chineseName: '澳大利亚',
        englishName: 'Australia',
        jaName: 'オーストラリア',
        twoLetterCode: 'AU',
        phoneCode: '+61',
      },
      {
        countryId: 504,
        chineseName: '新西兰',
        englishName: 'New Zealand',
        jaName: 'ニュージーランド',
        twoLetterCode: 'NZ',
        phoneCode: '+64',
      },
      {
        countryId: 520,
        chineseName: '巴布亚新几内亚',
        englishName: 'Papua New Guinea',
        jaName: 'パプアニューギニア',
        twoLetterCode: 'PG',
        phoneCode: '+675',
      },
      {
        countryId: 516,
        chineseName: '北马里亚纳',
        englishName: 'Northern Mariana Islands',
        jaName: '北マリアナ諸島',
        twoLetterCode: 'MP',
        phoneCode: '+1',
      },
      {
        countryId: 542,
        chineseName: '法属波利尼西亚',
        englishName: 'French Polynesia',
        jaName: 'フランス領ポリネシア',
        twoLetterCode: 'PF',
        phoneCode: '+689',
      },
      {
        countryId: 526,
        chineseName: '斐济',
        englishName: 'Fiji',
        jaName: 'フィジー',
        twoLetterCode: 'FJ',
        phoneCode: '+679',
      },
      {
        countryId: 518,
        chineseName: '关岛',
        englishName: 'Guam',
        jaName: 'グアム',
        twoLetterCode: 'GU',
        phoneCode: '+1671',
      },
      {
        countryId: 512,
        chineseName: '基里巴斯',
        englishName: 'Kiribati',
        jaName: 'キリバス',
        twoLetterCode: 'KI',
        phoneCode: '+686',
      },
      {
        countryId: 536,
        chineseName: '库克群岛',
        englishName: 'Cook Islands',
        jaName: 'クック諸島',
        twoLetterCode: 'CK',
        phoneCode: '+682',
      },
      {
        countryId: 510,
        chineseName: '马绍尔群岛',
        englishName: 'Marshall Islands',
        jaName: 'マーシャル諸島',
        twoLetterCode: 'MH',
        phoneCode: '+692',
      },
      {
        countryId: 548,
        chineseName: '美属萨摩亚',
        englishName: 'American Samoa',
        jaName: 'アメリカ領サモア',
        twoLetterCode: 'AS',
        phoneCode: '+684',
      },
      {
        countryId: 508,
        chineseName: '密克罗尼西亚联邦',
        englishName: 'Micronesia',
        jaName: 'ミクロネシア連邦',
        twoLetterCode: 'FM',
      },
      {
        countryId: 514,
        chineseName: '瑙鲁',
        englishName: 'Nauru',
        jaName: 'ナウル',
        twoLetterCode: 'NR',
        phoneCode: '+674',
      },
      {
        countryId: 538,
        chineseName: '纽埃',
        englishName: 'Niue',
        jaName: 'ニウエ',
        twoLetterCode: 'NU',
        phoneCode: '+683',
      },
      {
        countryId: 506,
        chineseName: '帕劳',
        englishName: 'Palau',
        jaName: 'パラオ',
        twoLetterCode: 'PW',
        phoneCode: '+680',
      },
      {
        countryId: 546,
        chineseName: '皮特凯恩',
        englishName: 'Pitcairn Islands',
        jaName: 'ピトケアン諸島',
        twoLetterCode: 'PN',
      },
      {
        countryId: 532,
        chineseName: '萨摩亚',
        englishName: 'Samoa',
        jaName: 'サモア',
        twoLetterCode: 'WS',
        phoneCode: '+685',
      },
      {
        countryId: 522,
        chineseName: '所罗门群岛',
        englishName: 'Solomon Islands',
        jaName: 'ソロモン諸島',
        twoLetterCode: 'SB',
        phoneCode: '+677',
      },
      {
        countryId: 534,
        chineseName: '汤加',
        englishName: 'Tonga',
        jaName: 'トンガ',
        twoLetterCode: 'TO',
        phoneCode: '+676',
      },
      {
        countryId: 530,
        chineseName: '图瓦卢',
        englishName: 'Tuvalu',
        jaName: 'ツバル',
        twoLetterCode: 'TV',
        phoneCode: '+688',
      },
      {
        countryId: 540,
        chineseName: '托克劳',
        englishName: 'Tokelau',
        jaName: 'トケラウ',
        twoLetterCode: 'TK',
        phoneCode: '+690',
      },
      {
        countryId: 544,
        chineseName: '瓦利斯和富图纳',
        englishName: 'Wallis & Futuna',
        jaName: 'ウォリス・フツナ',
        twoLetterCode: 'WF',
        phoneCode: '+681',
      },
      {
        countryId: 524,
        chineseName: '瓦努阿图',
        englishName: 'Vanuatu',
        jaName: 'バヌアツ',
        twoLetterCode: 'VU',
        phoneCode: '+678',
      },
      {
        countryId: 528,
        chineseName: '新喀里多尼亚',
        englishName: 'New Caledonia',
        jaName: 'ニューカレドニア',
        twoLetterCode: 'NC',
        phoneCode: '+687',
      },
    ],
  },
  {
    areaName: '独联体',
    areaEnglishName: 'Commonwealth of Independent States',
    areaJaName: '独立国家共同体',
    countryList: [
      {
        countryId: 152,
        chineseName: '俄罗斯',
        englishName: 'Russia',
        jaName: 'ロシア',
        twoLetterCode: 'RU',
        phoneCode: '+7',
      },
      {
        countryId: 154,
        chineseName: '乌克兰',
        englishName: 'Ukraine',
        jaName: 'ウクライナ',
        twoLetterCode: 'UA',
        phoneCode: '+380',
      },
      {
        countryId: 156,
        chineseName: '白俄罗斯',
        englishName: 'Belarus',
        jaName: 'ベラルーシ',
        twoLetterCode: 'BY',
        phoneCode: '+375',
      },
      {
        countryId: 162,
        chineseName: '哈萨克斯坦',
        englishName: 'Kazakhstan',
        jaName: 'カザフスタン',
        twoLetterCode: 'KZ',
        phoneCode: '+73',
      },
      {
        countryId: 164,
        chineseName: '吉尔吉斯斯坦',
        englishName: 'Kyrgyzstan',
        jaName: 'キルギス',
        twoLetterCode: 'KG',
        phoneCode: '+996',
      },
      {
        countryId: 158,
        chineseName: '摩尔多瓦',
        englishName: 'Moldova',
        jaName: 'モルドバ',
        twoLetterCode: 'MD',
        phoneCode: '+373',
      },
      {
        countryId: 160,
        chineseName: '塔吉克斯坦',
        englishName: 'Tajikistan',
        jaName: 'タジキスタン',
        twoLetterCode: 'TJ',
        phoneCode: '+992',
      },
      {
        countryId: 168,
        chineseName: '土库曼斯坦',
        englishName: 'Turkmenistan',
        jaName: 'トルクメニスタン',
        twoLetterCode: 'TM',
        phoneCode: '+993',
      },
      {
        countryId: 166,
        chineseName: '乌兹别克斯坦',
        englishName: 'Uzbekistan',
        jaName: 'ウズベキスタン',
        twoLetterCode: 'UZ',
        phoneCode: '+998',
      },
    ],
  },
  {
    areaName: '欧洲',
    areaEnglishName: 'Europe',
    areaJaName: 'ヨーロッパ',
    countryList: [
      {
        countryId: 446,
        chineseName: '爱尔兰',
        englishName: 'Ireland',
        jaName: 'アイルランド',
        twoLetterCode: 'IE',
        phoneCode: '+353',
      },
      {
        countryId: 424,
        chineseName: '奥地利',
        englishName: 'Austria',
        jaName: 'オーストリア',
        twoLetterCode: 'AT',
        phoneCode: '+43',
      },
      {
        countryId: 460,
        chineseName: '比利时',
        englishName: 'Belgium',
        jaName: 'ベルギー',
        twoLetterCode: 'BE',
        phoneCode: '+32',
      },
      {
        countryId: 442,
        chineseName: '冰岛',
        englishName: 'Iceland',
        jaName: 'アイスランド',
        twoLetterCode: 'IS',
        phoneCode: '+354',
      },
      {
        countryId: 438,
        chineseName: '丹麦',
        englishName: 'Denmark',
        jaName: 'デンマーク',
        twoLetterCode: 'DK',
        phoneCode: '+45',
      },
      {
        countryId: 422,
        chineseName: '德国',
        englishName: 'Germany',
        jaName: 'ドイツ',
        twoLetterCode: 'DE',
        phoneCode: '+49',
      },
      {
        countryId: 418,
        chineseName: '法国',
        englishName: 'France',
        jaName: 'フランス',
        twoLetterCode: 'FR',
        phoneCode: '+33',
      },
      {
        countryId: 440,
        chineseName: '芬兰',
        englishName: 'Finland',
        jaName: 'フィンランド',
        twoLetterCode: 'FI',
        phoneCode: '+358',
      },
      {
        countryId: 448,
        chineseName: '荷兰',
        englishName: 'Netherlands',
        jaName: 'オランダ',
        twoLetterCode: 'NL',
        phoneCode: '+31',
      },
      {
        countryId: 436,
        chineseName: '挪威',
        englishName: 'Norway',
        jaName: 'ノルウェー',
        twoLetterCode: 'NO',
        phoneCode: '+47',
      },
      {
        countryId: 428,
        chineseName: '葡萄牙',
        englishName: 'Portugal',
        jaName: 'ポルトガル',
        twoLetterCode: 'PT',
        phoneCode: '+351',
      },
      {
        countryId: 434,
        chineseName: '瑞典',
        englishName: 'Sweden',
        jaName: 'スウェーデン',
        twoLetterCode: 'SE',
        phoneCode: '+46',
      },
      {
        countryId: 444,
        chineseName: '瑞士',
        englishName: 'Switzerland',
        jaName: 'スイス',
        twoLetterCode: 'CH',
        phoneCode: '+41',
      },
      {
        countryId: 426,
        chineseName: '西班牙',
        englishName: 'Spain',
        jaName: 'スペイン',
        twoLetterCode: 'ES',
        phoneCode: '+34',
      },
      {
        countryId: 432,
        chineseName: '希腊',
        englishName: 'Greece',
        jaName: 'ギリシャ',
        twoLetterCode: 'GR',
        phoneCode: '+30',
      },
      {
        countryId: 430,
        chineseName: '意大利',
        englishName: 'Italy',
        jaName: 'イタリア',
        twoLetterCode: 'IT',
        phoneCode: '+39',
      },
      {
        countryId: 420,
        chineseName: '英国',
        englishName: 'United Kingdom',
        jaName: 'イギリス',
        twoLetterCode: 'GB',
        phoneCode: '+44',
      },
      {
        countryId: 474,
        chineseName: '阿尔巴尼亚',
        englishName: 'Albania',
        jaName: 'アルバニア',
        twoLetterCode: 'AL',
        phoneCode: '+355',
      },
      {
        countryId: 490,
        chineseName: '爱沙尼亚',
        englishName: 'Estonia',
        jaName: 'エストニア',
        twoLetterCode: 'EE',
        phoneCode: '+372',
      },
      {
        countryId: 486,
        chineseName: '安道尔',
        englishName: 'Andorra',
        jaName: 'アンドラ',
        twoLetterCode: 'AD',
        phoneCode: '+376',
      },
      {
        countryId: 468,
        chineseName: '保加利亚',
        englishName: 'Bulgaria',
        jaName: 'ブルガリア',
        twoLetterCode: 'BG',
        phoneCode: '+359',
      },
      {
        countryId: 500,
        chineseName: '波黑',
        englishName: 'Bosnia & Herzegovina',
        jaName: 'ボスニア・ヘルツェゴビナ',
        twoLetterCode: 'BA',
        phoneCode: '+387',
      },
      {
        countryId: 450,
        chineseName: '波兰',
        englishName: 'Poland',
        jaName: 'ポーランド',
        twoLetterCode: 'PL',
        phoneCode: '+48',
      },
      {
        countryId: 496,
        chineseName: '法罗群岛',
        englishName: 'Faroe Islands',
        jaName: 'フェロー諸島',
        twoLetterCode: 'FO',
        phoneCode: '+298',
      },
      {
        countryId: 484,
        chineseName: '梵蒂冈',
        englishName: 'Vatican City',
        jaName: 'バチカン市国',
        twoLetterCode: 'VA',
        phoneCode: '+379',
      },
      {
        countryId: 556,
        chineseName: '根西岛',
        englishName: 'Guernsey',
        jaName: 'ガーンジー',
        twoLetterCode: 'GG',
        phoneCode: '+1481',
      },
      {
        countryId: 480,
        chineseName: '黑山',
        englishName: 'Montenegro',
        jaName: 'モンテネグロ',
        twoLetterCode: 'ME',
        phoneCode: '+382',
      },
      {
        countryId: 452,
        chineseName: '捷克',
        englishName: 'Czechia',
        jaName: 'チェコ',
        twoLetterCode: 'CZ',
        phoneCode: '+420',
      },
      {
        countryId: 478,
        chineseName: '克罗地亚',
        englishName: 'Croatia',
        jaName: 'クロアチア',
        twoLetterCode: 'HR',
        phoneCode: '+385',
      },
      {
        countryId: 492,
        chineseName: '拉脱维亚',
        englishName: 'Latvia',
        jaName: 'ラトビア',
        twoLetterCode: 'LV',
        phoneCode: '+371',
      },
      {
        countryId: 494,
        chineseName: '立陶宛',
        englishName: 'Lithuania',
        jaName: 'リトアニア',
        twoLetterCode: 'LT',
        phoneCode: '+370',
      },
      {
        countryId: 458,
        chineseName: '列支敦士登',
        englishName: 'Liechtenstein',
        jaName: 'リヒテンシュタイン',
        twoLetterCode: 'LI',
        phoneCode: '+423',
      },
      {
        countryId: 462,
        chineseName: '卢森堡',
        englishName: 'Luxembourg',
        jaName: 'ルクセンブルク',
        twoLetterCode: 'LU',
        phoneCode: '+352',
      },
      {
        countryId: 466,
        chineseName: '罗马尼亚',
        englishName: 'Romania',
        jaName: 'ルーマニア',
        twoLetterCode: 'RO',
        phoneCode: '+40',
      },
      {
        countryId: 554,
        chineseName: '马恩岛',
        englishName: 'Isle of man',
        jaName: 'マン島',
        twoLetterCode: 'IM',
      },
      {
        countryId: 482,
        chineseName: '马耳他',
        englishName: 'Malta',
        jaName: 'マルタ',
        twoLetterCode: 'MT',
        phoneCode: '+356',
      },
      {
        countryId: 550,
        chineseName: '马约特',
        englishName: 'Mayotte',
        jaName: 'マヨット',
        twoLetterCode: 'YT',
        phoneCode: '+269',
      },
      {
        countryId: 464,
        chineseName: '摩纳哥',
        englishName: 'Monaco',
        jaName: 'モナコ',
        twoLetterCode: 'MC',
        phoneCode: '+377',
      },
      {
        countryId: 472,
        chineseName: '前南马其顿',
        englishName: 'North Macedonia',
        jaName: '北マケドニア',
        twoLetterCode: 'MK',
        phoneCode: '+389',
      },
      {
        countryId: 470,
        chineseName: '塞尔维亚',
        englishName: 'Serbia',
        jaName: 'セルビア',
        twoLetterCode: 'RS',
        phoneCode: '+381',
      },
      {
        countryId: 488,
        chineseName: '圣马力诺',
        englishName: 'San Marino',
        jaName: 'サンマリノ',
        twoLetterCode: 'SM',
        phoneCode: '+378',
      },
      {
        countryId: 454,
        chineseName: '斯洛伐克',
        englishName: 'Slovakia',
        jaName: 'スロバキア',
        twoLetterCode: 'SK',
        phoneCode: '+421',
      },
      {
        countryId: 476,
        chineseName: '斯洛文尼亚',
        englishName: 'Slovenia',
        jaName: 'スロベニア',
        twoLetterCode: 'SI',
        phoneCode: '+386',
      },
      {
        countryId: 456,
        chineseName: '匈牙利',
        englishName: 'Hungary',
        jaName: 'ハンガリー',
        twoLetterCode: 'HU',
        phoneCode: '+36',
      },
      {
        countryId: 552,
        chineseName: '泽西岛',
        englishName: 'Jersey',
        jaName: 'ジャージー',
        twoLetterCode: 'JE',
      },
      {
        countryId: 498,
        chineseName: '直布罗陀',
        englishName: 'Gibraltar',
        jaName: 'ジブラルタル',
        twoLetterCode: 'GI',
        phoneCode: '+350',
      },
    ],
  },
  {
    areaName: '非洲',
    areaEnglishName: 'Africa',
    areaJaName: 'アフリカ',
    countryList: [
      {
        countryId: 208,
        chineseName: '埃及',
        englishName: 'Egypt',
        jaName: 'エジプト',
        twoLetterCode: 'EG',
        phoneCode: '+20',
      },
      {
        countryId: 250,
        chineseName: '南非',
        englishName: 'South Africa',
        jaName: '南アフリカ',
        twoLetterCode: 'ZA',
        phoneCode: '+27',
      },
      {
        countryId: 214,
        chineseName: '阿尔及利亚',
        englishName: 'Algeria',
        jaName: 'アルジェリア',
        twoLetterCode: 'DZ',
        phoneCode: '+213',
      },
      {
        countryId: 230,
        chineseName: '埃塞俄比亚',
        englishName: 'Ethiopia',
        jaName: 'エチオピア',
        twoLetterCode: 'ET',
        phoneCode: '+251',
      },
      {
        countryId: 254,
        chineseName: '安哥拉',
        englishName: 'Angola',
        jaName: 'アンゴラ',
        twoLetterCode: 'AO',
        phoneCode: '+244',
      },
      {
        countryId: 310,
        chineseName: '贝宁',
        englishName: 'Benin',
        jaName: 'ベニン',
        twoLetterCode: 'BJ',
        phoneCode: '+229',
      },
      {
        countryId: 272,
        chineseName: '博茨瓦纳',
        englishName: 'Botswana',
        jaName: 'ボツワナ',
        twoLetterCode: 'BW',
        phoneCode: '+267',
      },
      {
        countryId: 292,
        chineseName: '布基纳法索',
        englishName: 'Burkina Faso',
        jaName: 'ブルキナファソ',
        twoLetterCode: 'BF',
        phoneCode: '+226',
      },
      {
        countryId: 246,
        chineseName: '布隆迪',
        englishName: 'Burundi',
        jaName: 'ブルニジャ',
        twoLetterCode: 'BI',
        phoneCode: '+257',
      },
      {
        countryId: 316,
        chineseName: '赤道几内亚',
        englishName: 'Equatorial Guinea',
        jaName: '赤道ギニア',
        twoLetterCode: 'GQ',
        phoneCode: '+240',
      },
      {
        countryId: 308,
        chineseName: '多哥',
        englishName: 'Togo',
        jaName: 'トーゴ',
        twoLetterCode: 'TG',
        phoneCode: '+228',
      },
      {
        countryId: 232,
        chineseName: '厄立特里亚',
        englishName: 'Eritrea',
        jaName: 'エリトリア',
        twoLetterCode: 'ER',
        phoneCode: '+291',
      },
      {
        countryId: 298,
        chineseName: '佛得角',
        englishName: 'Cape Verde',
        jaName: 'カーボベルデ',
        twoLetterCode: 'CV',
        phoneCode: '+238',
      },
      {
        countryId: 288,
        chineseName: '冈比亚',
        englishName: 'Gambia',
        jaName: 'ガンビア',
        twoLetterCode: 'GM',
        phoneCode: '+220',
      },
      {
        countryId: 564,
        chineseName: '刚果民主共和国',
        englishName: 'Democratic Republic of the Congo',
        jaName: 'コンゴ民主共和国',
        twoLetterCode: 'CD',
        phoneCode: '+243',
      },
      {
        countryId: 236,
        chineseName: '吉布提',
        englishName: 'Djibouti',
        jaName: 'ジブチ',
        twoLetterCode: 'DJ',
        phoneCode: '+253',
      },
      {
        countryId: 294,
        chineseName: '几内亚',
        englishName: 'Guinea',
        jaName: 'ギニア',
        twoLetterCode: 'GN',
        phoneCode: '+224',
      },
      {
        countryId: 296,
        chineseName: '几内亚比绍',
        englishName: 'Guinea-Bissau',
        jaName: 'ギニアビサウ',
        twoLetterCode: 'GW',
        phoneCode: '+245',
      },
      {
        countryId: 224,
        chineseName: '加那利群岛',
        englishName: 'Pseudo-Accents',
        jaName: 'パセオアクセント',
        twoLetterCode: 'XA',
      },
      {
        countryId: 306,
        chineseName: '加纳',
        englishName: 'Ghana',
        jaName: 'ガナ',
        twoLetterCode: 'GH',
        phoneCode: '+233',
      },
      {
        countryId: 318,
        chineseName: '加蓬',
        englishName: 'Gabon',
        jaName: 'ガボン',
        twoLetterCode: 'GA',
        phoneCode: '+241',
      },
      {
        countryId: 256,
        chineseName: '津巴布韦',
        englishName: 'Zimbabwe',
        jaName: 'ジンバブエ',
        twoLetterCode: 'ZW',
        phoneCode: '+263',
      },
      {
        countryId: 314,
        chineseName: '喀麦隆',
        englishName: 'Cameroon',
        jaName: 'カメロン',
        twoLetterCode: 'CM',
        phoneCode: '+237',
      },
      {
        countryId: 264,
        chineseName: '科摩罗',
        englishName: 'Comoros',
        jaName: 'コモロ',
        twoLetterCode: 'KM',
        phoneCode: '+269',
      },
      {
        countryId: 304,
        chineseName: '科特迪瓦',
        englishName: 'Côte d’Ivoire',
        jaName: 'コートデイヴォア',
        twoLetterCode: 'CI',
        phoneCode: '+225',
      },
      {
        countryId: 238,
        chineseName: '肯尼亚',
        englishName: 'Kenya',
        jaName: 'ケニア',
        twoLetterCode: 'KE',
        phoneCode: '+254',
      },
      {
        countryId: 274,
        chineseName: '莱索托',
        englishName: 'Lesotho',
        jaName: 'レソト',
        twoLetterCode: 'LS',
        phoneCode: '+266',
      },
      {
        countryId: 302,
        chineseName: '利比里亚',
        englishName: 'Liberia',
        jaName: 'リベリア',
        twoLetterCode: 'LR',
        phoneCode: '+231',
      },
      {
        countryId: 210,
        chineseName: '利比亚',
        englishName: 'Libya',
        jaName: 'リビア',
        twoLetterCode: 'LY',
        phoneCode: '+218',
      },
      {
        countryId: 276,
        chineseName: '留尼汪',
        englishName: 'Réunion',
        jaName: 'レユニオン',
        twoLetterCode: 'RE',
        phoneCode: '+262',
      },
      {
        countryId: 244,
        chineseName: '卢旺达',
        englishName: 'Rwanda',
        jaName: 'ルワンダ',
        twoLetterCode: 'RW',
        phoneCode: '+250',
      },
      {
        countryId: 260,
        chineseName: '马达加斯加',
        englishName: 'Madagascar',
        jaName: 'マダガスカル',
        twoLetterCode: 'MG',
        phoneCode: '+261',
      },
      {
        countryId: 218,
        chineseName: '马德拉',
        englishName: 'Madeira',
        jaName: 'マディア',
        twoLetterCode: 'XI',
      },
      {
        countryId: 268,
        chineseName: '马拉维',
        englishName: 'Malawi',
        jaName: 'マラウイ',
        twoLetterCode: 'MW',
        phoneCode: '+265',
      },
      {
        countryId: 290,
        chineseName: '马里',
        englishName: 'Mali',
        jaName: 'マリ',
        twoLetterCode: 'ML',
        phoneCode: '+223',
      },
      {
        countryId: 258,
        chineseName: '毛里求斯',
        englishName: 'Mauritius',
        jaName: 'モーリシャス',
        twoLetterCode: 'MU',
        phoneCode: '+230',
      },
      {
        countryId: 284,
        chineseName: '毛利塔尼亚',
        englishName: 'Mauritania',
        jaName: 'モーリタニア',
        twoLetterCode: 'MR',
      },
      {
        countryId: 216,
        chineseName: '摩洛哥',
        englishName: 'Morocco',
        jaName: 'モロッコ',
        twoLetterCode: 'MA',
        phoneCode: '+212',
      },
      {
        countryId: 270,
        chineseName: '莫桑比克',
        englishName: 'Mozambique',
        jaName: 'モザンビーク',
        twoLetterCode: 'MZ',
        phoneCode: '+258',
      },
      {
        countryId: 266,
        chineseName: '纳米比亚',
        englishName: 'Namibia',
        jaName: 'ナミビア',
        twoLetterCode: 'NA',
        phoneCode: '+264',
      },
      {
        countryId: 228,
        chineseName: '南苏丹',
        englishName: 'South Sudan',
        jaName: '南スーダン',
        twoLetterCode: 'SS',
      },
      {
        countryId: 282,
        chineseName: '尼日尔',
        englishName: 'Niger',
        jaName: 'ネィジェル',
        twoLetterCode: 'NE',
        phoneCode: '+227',
      },
      {
        countryId: 280,
        chineseName: '尼日利亚',
        englishName: 'Nigeria',
        jaName: 'ナイジェリア',
        twoLetterCode: 'NG',
        phoneCode: '+234',
      },
      {
        countryId: 300,
        chineseName: '塞拉利昂',
        englishName: 'Sierra Leone',
        jaName: 'シエラレオネ',
        twoLetterCode: 'SL',
        phoneCode: '+232',
      },
      {
        countryId: 286,
        chineseName: '塞内加尔',
        englishName: 'Senegal',
        jaName: 'セネガル',
        twoLetterCode: 'SN',
        phoneCode: '+221',
      },
      {
        countryId: 248,
        chineseName: '塞舌尔',
        englishName: 'Seychelles',
        jaName: 'セイチェラ',
        twoLetterCode: 'SC',
        phoneCode: '+248',
      },
      {
        countryId: 320,
        chineseName: '圣多美和普林西比',
        englishName: 'São Tomé & Príncipe',
        jaName: 'サントメイルとプリンシペ',
        twoLetterCode: 'ST',
        phoneCode: '+239',
      },
      {
        countryId: 278,
        chineseName: '圣赫勒拿',
        englishName: 'St. Helena',
        jaName: 'スケルトネラ',
        twoLetterCode: 'SH',
        phoneCode: '+290',
      },
      {
        countryId: 262,
        chineseName: '斯威士兰',
        englishName: 'Eswatini',
        jaName: 'エスワチニ',
        twoLetterCode: 'SZ',
        phoneCode: '+268',
      },
      {
        countryId: 226,
        chineseName: '苏丹',
        englishName: 'Sudan',
        jaName: 'スウダン',
        twoLetterCode: 'SD',
        phoneCode: '+249',
      },
      {
        countryId: 234,
        chineseName: '索马里',
        englishName: 'Somalia',
        jaName: 'ソマリア',
        twoLetterCode: 'SO',
        phoneCode: '+252',
      },
      {
        countryId: 240,
        chineseName: '坦桑尼亚',
        englishName: 'Tanzania',
        jaName: 'タンザニア',
        twoLetterCode: 'TZ',
        phoneCode: '+255',
      },
      {
        countryId: 212,
        chineseName: '突尼斯',
        englishName: 'Tunisia',
        jaName: 'チュニジア',
        twoLetterCode: 'TN',
        phoneCode: '+216',
      },
      {
        countryId: 242,
        chineseName: '乌干达',
        englishName: 'Uganda',
        jaName: 'ウガンダ',
        twoLetterCode: 'UG',
        phoneCode: '+256',
      },
      {
        countryId: 222,
        chineseName: '西撒哈拉',
        englishName: 'Western Sahara',
        jaName: 'ウェストサハラ',
        twoLetterCode: 'EH',
      },
      {
        countryId: 220,
        chineseName: '亚速尔',
        englishName: 'Azores',
        jaName: 'アゾレス',
        twoLetterCode: 'XH',
      },
      {
        countryId: 252,
        chineseName: '赞比亚',
        englishName: 'Zambia',
        jaName: 'ザムバイ',
        twoLetterCode: 'ZM',
        phoneCode: '+260',
      },
      {
        countryId: 312,
        chineseName: '乍得',
        englishName: 'Chad',
        jaName: 'チャド',
        twoLetterCode: 'TD',
        phoneCode: '+235',
      },
    ],
  },
  {
    areaName: '中东',
    areaEnglishName: 'The Middle East',
    areaJaName: '中東',
    countryList: [
      {
        countryId: 178,
        chineseName: '阿联酋',
        englishName: 'United Arab Emirates',
        jaName: 'アラブ首長国連邦',
        twoLetterCode: 'AE',
        phoneCode: '+971',
      },
      {
        countryId: 170,
        chineseName: '沙特阿拉伯',
        englishName: 'Saudi Arabia',
        jaName: 'サウジアラビア',
        twoLetterCode: 'SA',
        phoneCode: '+966',
      },
      {
        countryId: 186,
        chineseName: '土耳其',
        englishName: 'Turkey',
        jaName: 'トルコ',
        twoLetterCode: 'TR',
        phoneCode: '+90',
      },
      {
        countryId: 172,
        chineseName: '伊朗',
        englishName: 'Iran',
        jaName: 'イラン',
        twoLetterCode: 'IR',
        phoneCode: '+98',
      },
      {
        countryId: 188,
        chineseName: '以色列',
        englishName: 'Israel',
        jaName: 'イギリス',
        twoLetterCode: 'IL',
        phoneCode: '+972',
      },
      {
        countryId: 562,
        chineseName: '阿富汗',
        englishName: 'Afghanistan',
        jaName: 'アフガニスタン',
        twoLetterCode: 'AF',
        phoneCode: '+93',
      },
      {
        countryId: 180,
        chineseName: '阿曼',
        englishName: 'Oman',
        jaName: 'オマン',
        twoLetterCode: 'OM',
        phoneCode: '+968',
      },
      {
        countryId: 206,
        chineseName: '阿塞拜疆',
        englishName: 'Azerbaijan',
        jaName: 'アゼルバイジャン',
        twoLetterCode: 'AZ',
        phoneCode: '+994',
      },
      {
        countryId: 190,
        chineseName: '巴勒斯坦',
        englishName: 'Palestinian Territories',
        jaName: 'パレスチナ',
        twoLetterCode: 'PS',
        phoneCode: '+970',
      },
      {
        countryId: 184,
        chineseName: '巴林',
        englishName: 'Bahrain',
        jaName: 'バーヒン',
        twoLetterCode: 'BH',
        phoneCode: '+973',
      },
      {
        countryId: 202,
        chineseName: '格鲁吉亚',
        englishName: 'Georgia',
        jaName: 'ジョージア',
        twoLetterCode: 'GE',
        phoneCode: '+995',
      },
      {
        countryId: 182,
        chineseName: '卡塔尔',
        englishName: 'Qatar',
        jaName: 'カタール',
        twoLetterCode: 'QA',
        phoneCode: '+974',
      },
      {
        countryId: 176,
        chineseName: '科威特',
        englishName: 'Kuwait',
        jaName: 'クウェート',
        twoLetterCode: 'KW',
        phoneCode: '+965',
      },
      {
        countryId: 194,
        chineseName: '黎巴嫩',
        englishName: 'Lebanon',
        jaName: 'レバノン',
        twoLetterCode: 'LB',
        phoneCode: '+961',
      },
      {
        countryId: 200,
        chineseName: '塞浦路斯',
        englishName: 'Cyprus',
        jaName: 'キプロス',
        twoLetterCode: 'CY',
        phoneCode: '+357',
      },
      {
        countryId: 192,
        chineseName: '叙利亚',
        englishName: 'Syria',
        jaName: 'シリア',
        twoLetterCode: 'SY',
        phoneCode: '+963',
      },
      {
        countryId: 204,
        chineseName: '亚美尼亚',
        englishName: 'Armenia',
        jaName: 'アルメニア',
        twoLetterCode: 'AM',
        phoneCode: '+374',
      },
      {
        countryId: 198,
        chineseName: '也门',
        englishName: 'Yemen',
        jaName: 'イメン',
        twoLetterCode: 'YE',
        phoneCode: '+967',
      },
      {
        countryId: 174,
        chineseName: '伊拉克',
        englishName: 'Iraq',
        jaName: 'イラク',
        twoLetterCode: 'IQ',
        phoneCode: '+964',
      },
      {
        countryId: 196,
        chineseName: '约旦',
        englishName: 'Jordan',
        jaName: 'イヨーン',
        twoLetterCode: 'JO',
        phoneCode: '+962',
      },
    ],
  },
  {
    areaName: '东亚',
    areaEnglishName: 'East Asia',
    areaJaName: '東アジア',
    countryList: [
      {
        countryId: 122,
        chineseName: '韩国',
        englishName: 'South Korea',
        jaName: '韓国',
        twoLetterCode: 'KR',
        phoneCode: '+82',
      },
      {
        countryId: 124,
        chineseName: '日本',
        englishName: 'Japan',
        jaName: '日本',
        twoLetterCode: 'JP',
        phoneCode: '+81',
      },
      {
        countryId: 132,
        chineseName: '中国',
        englishName: 'China',
        jaName: '中国',
        twoLetterCode: 'CN',
        phoneCode: '+86',
      },
      {
        countryId: 130,
        chineseName: '中国澳门',
        englishName: 'Macao SAR China',
        jaName: '中国マカオ',
        twoLetterCode: 'MO',
        phoneCode: '+853',
      },
      {
        countryId: 126,
        chineseName: '中国台湾',
        englishName: 'Taiwan(China)',
        jaName: '中国台湾',
        twoLetterCode: 'TW',
        phoneCode: '+886',
      },
      {
        countryId: 128,
        chineseName: '中国香港',
        englishName: 'Hong Kong SAR China',
        jaName: '中国香港',
        twoLetterCode: 'HK',
        phoneCode: '+852',
      },
      {
        countryId: 136,
        chineseName: '朝鲜',
        englishName: 'North Korea',
        jaName: '北朝鮮',
        twoLetterCode: 'KP',
        phoneCode: '+850',
      },
      {
        countryId: 134,
        chineseName: '蒙古',
        englishName: 'Mongolia',
        jaName: 'モンゴル',
        twoLetterCode: 'MN',
        phoneCode: '+976',
      },
    ],
  },
  {
    areaName: '南亚',
    areaEnglishName: 'South Asia',
    areaJaName: '南アジア',
    countryList: [
      {
        countryId: 138,
        chineseName: '印度',
        englishName: 'India',
        jaName: 'インド',
        twoLetterCode: 'IN',
        phoneCode: '+91',
      },
      {
        countryId: 140,
        chineseName: '巴基斯坦',
        englishName: 'Pakistan',
        jaName: 'パキスタン',
        twoLetterCode: 'PK',
        phoneCode: '+92',
      },
      {
        countryId: 150,
        chineseName: '不丹',
        englishName: 'Bhutan',
        jaName: '不丹',
        twoLetterCode: 'BT',
        phoneCode: '+975',
      },
      {
        countryId: 148,
        chineseName: '马尔代夫',
        englishName: 'Maldives',
        jaName: 'マルディーブ',
        twoLetterCode: 'MV',
        phoneCode: '+960',
      },
      {
        countryId: 142,
        chineseName: '孟加拉国',
        englishName: 'Bangladesh',
        jaName: 'バングラデシュ',
        twoLetterCode: 'BD',
        phoneCode: '+880',
      },
      {
        countryId: 144,
        chineseName: '尼泊尔',
        englishName: 'Nepal',
        jaName: 'ネパル',
        twoLetterCode: 'NP',
        phoneCode: '+977',
      },
      {
        countryId: 146,
        chineseName: '斯里兰卡',
        englishName: 'Sri Lanka',
        jaName: 'スリランカ',
        twoLetterCode: 'LK',
        phoneCode: '+94',
      },
    ],
  },
  {
    areaName: '北美',
    areaEnglishName: 'North America',
    areaJaName: '北アメリカ',
    countryList: [
      {
        countryId: 324,
        chineseName: '加拿大',
        englishName: 'Canada',
        jaName: 'カナダ',
        twoLetterCode: 'CA',
        phoneCode: '+1',
      },
      {
        countryId: 322,
        chineseName: '美国',
        englishName: 'United States',
        jaName: 'アメリカ合衆国',
        twoLetterCode: 'US',
        phoneCode: '+1',
      },
      {
        countryId: 326,
        chineseName: '墨西哥',
        englishName: 'Mexico',
        jaName: 'メキシコ',
        twoLetterCode: 'MX',
        phoneCode: '+52',
      },
      {
        countryId: 328,
        chineseName: '格陵兰',
        englishName: 'Greenland',
        jaName: 'グリーンランド',
        twoLetterCode: 'GL',
        phoneCode: '+299',
      },
      {
        countryId: 558,
        chineseName: '荷属安的列斯',
        englishName: 'Netherlands Antilles',
        jaName: 'ネガリエ',
        twoLetterCode: 'AN',
        phoneCode: '+599',
      },
      {
        countryId: 330,
        chineseName: '圣皮埃尔和密克隆',
        englishName: 'St. Pierre & Miquelon',
        jaName: 'サンピエールミクロン',
        twoLetterCode: 'PM',
        phoneCode: '+508',
      },
    ],
  },
  {
    areaName: '南美',
    areaEnglishName: 'South America',
    areaJaName: '南アメリカ',
    countryList: [
      {
        countryId: 336,
        chineseName: '阿根廷',
        englishName: 'Argentina',
        jaName: 'アルゼンチン',
        twoLetterCode: 'AR',
        phoneCode: '+54',
      },
      {
        countryId: 332,
        chineseName: '巴西',
        englishName: 'Brazil',
        jaName: 'ブラジル',
        twoLetterCode: 'BR',
        phoneCode: '+55',
      },
      {
        countryId: 334,
        chineseName: '哥伦比亚',
        englishName: 'Colombia',
        jaName: 'コロンビア',
        twoLetterCode: 'CO',
        phoneCode: '+57',
      },
      {
        countryId: 398,
        chineseName: '阿鲁巴',
        englishName: 'Aruba',
        jaName: 'アルバ',
        twoLetterCode: 'AW',
        phoneCode: '+297',
      },
      {
        countryId: 390,
        chineseName: '安圭拉',
        englishName: 'Anguilla',
        jaName: 'アンギラ',
        twoLetterCode: 'AI',
        phoneCode: '+1264',
      },
      {
        countryId: 370,
        chineseName: '安提瓜和巴布达',
        englishName: 'Antigua & Barbuda',
        jaName: 'アンティグアバーブーダ',
        twoLetterCode: 'AG',
        phoneCode: '+1268',
      },
      {
        countryId: 382,
        chineseName: '巴巴多斯',
        englishName: 'Barbados',
        jaName: 'バルバドス',
        twoLetterCode: 'BB',
        phoneCode: '+1246',
      },
      {
        countryId: 348,
        chineseName: '巴哈马',
        englishName: 'Bahamas',
        jaName: 'バハマ',
        twoLetterCode: 'BS',
        phoneCode: '+1242',
      },
      {
        countryId: 352,
        chineseName: '巴拉圭',
        englishName: 'Paraguay',
        jaName: 'パラグアイ',
        twoLetterCode: 'PY',
        phoneCode: '+595',
      },
      {
        countryId: 344,
        chineseName: '巴拿马',
        englishName: 'Panama',
        jaName: 'パナマ',
        twoLetterCode: 'PA',
        phoneCode: '+507',
      },
      {
        countryId: 408,
        chineseName: '百慕大',
        englishName: 'Bermuda',
        jaName: 'ベルモダ',
        twoLetterCode: 'BM',
        phoneCode: '+1441',
      },
      {
        countryId: 386,
        chineseName: '波多黎各',
        englishName: 'Puerto Rico',
        jaName: 'プエルトリコ',
        twoLetterCode: 'PR',
        phoneCode: '+1809',
      },
      {
        countryId: 360,
        chineseName: '玻利维亚',
        englishName: 'Bolivia',
        jaName: 'ボリビア',
        twoLetterCode: 'BO',
        phoneCode: '+591',
      },
      {
        countryId: 412,
        chineseName: '伯利兹',
        englishName: 'Belize',
        jaName: 'ベリーズ',
        twoLetterCode: 'BZ',
        phoneCode: '+501',
      },
      {
        countryId: 368,
        chineseName: '多米尼加',
        englishName: 'Dominican Republic',
        jaName: 'ドミニカ共和国',
        twoLetterCode: 'DO',
        phoneCode: '+1809',
      },
      {
        countryId: 374,
        chineseName: '多米尼克',
        englishName: 'Dominica',
        jaName: 'ドミニカ',
        twoLetterCode: 'DM',
        phoneCode: '+1767',
      },
      {
        countryId: 354,
        chineseName: '厄瓜多尔',
        englishName: 'Ecuador',
        jaName: 'エクアドル',
        twoLetterCode: 'EC',
        phoneCode: '+593',
      },
      {
        countryId: 410,
        chineseName: '法属圣马丁',
        englishName: 'St. Martin',
        jaName: 'スマートイン',
        twoLetterCode: 'MF',
      },
      {
        countryId: 364,
        chineseName: '哥斯达黎加',
        englishName: 'Costa Rica',
        jaName: 'コスタリカ',
        twoLetterCode: 'CR',
        phoneCode: '+506',
      },
      {
        countryId: 380,
        chineseName: '格林纳达',
        englishName: 'Grenada',
        jaName: 'グレナダ',
        twoLetterCode: 'GD',
        phoneCode: '+1473',
      },
      {
        countryId: 346,
        chineseName: '古巴',
        englishName: 'Cuba',
        jaName: 'キューバ',
        twoLetterCode: 'CU',
        phoneCode: '+53',
      },
      {
        countryId: 394,
        chineseName: '瓜德罗普',
        englishName: 'Guadeloupe',
        jaName: 'グアドループ',
        twoLetterCode: 'GP',
        phoneCode: '+590',
      },
      {
        countryId: 560,
        chineseName: '圭亚那',
        englishName: 'Guyana',
        jaName: 'ガイアナ',
        twoLetterCode: 'GY',
        phoneCode: '+592',
      },
      {
        countryId: 366,
        chineseName: '海地',
        englishName: 'Haiti',
        jaName: 'ハイティ',
        twoLetterCode: 'HT',
        phoneCode: '+509',
      },
      {
        countryId: 402,
        chineseName: '荷属圣马丁',
        englishName: 'Sint Maarten',
        jaName: 'スマーテン',
        twoLetterCode: 'SX',
      },
      {
        countryId: 416,
        chineseName: '洪都拉斯',
        englishName: 'Honduras',
        jaName: 'ホンダース',
        twoLetterCode: 'HN',
        phoneCode: '+504',
      },
      {
        countryId: 406,
        chineseName: '开曼群岛',
        englishName: 'Cayman Islands',
        jaName: 'カイマン諸島',
        twoLetterCode: 'KY',
        phoneCode: '+1345',
      },
      {
        countryId: 400,
        chineseName: '库拉索',
        englishName: 'Curaçao',
        jaName: 'キュラソー',
        twoLetterCode: 'CW',
      },
      {
        countryId: 396,
        chineseName: '马提尼克',
        englishName: 'Martinique',
        jaName: 'マーティニック',
        twoLetterCode: 'MQ',
        phoneCode: '+596',
      },
      {
        countryId: 392,
        chineseName: '蒙特塞拉特',
        englishName: 'Montserrat',
        jaName: 'モントセラト',
        twoLetterCode: 'MS',
        phoneCode: '+1664',
      },
      {
        countryId: 338,
        chineseName: '秘鲁',
        englishName: 'Peru',
        jaName: 'ペルー',
        twoLetterCode: 'PE',
        phoneCode: '+51',
      },
      {
        countryId: 362,
        chineseName: '尼加拉瓜',
        englishName: 'Nicaragua',
        jaName: 'ニカラグア',
        twoLetterCode: 'NI',
        phoneCode: '+505',
      },
      {
        countryId: 414,
        chineseName: '萨尔瓦多',
        englishName: 'El Salvador',
        jaName: 'エルサルバドル',
        twoLetterCode: 'SV',
        phoneCode: '+503',
      },
      {
        countryId: 372,
        chineseName: '圣基茨和尼维斯',
        englishName: 'St. Kitts & Nevis',
        jaName: 'セントクリストファーネイビス',
        twoLetterCode: 'KN',
        phoneCode: '+1869',
      },
      {
        countryId: 376,
        chineseName: '圣卢西亚',
        englishName: 'St. Lucia',
        jaName: 'セントルシア',
        twoLetterCode: 'LC',
        phoneCode: '+1758',
      },
      {
        countryId: 378,
        chineseName: '圣文森特和格林纳丁斯',
        englishName: 'St. Vincent & Grenadines',
        jaName: 'セントビンテントグレナディンス',
        twoLetterCode: 'VC',
        phoneCode: '+1784',
      },
      {
        countryId: 404,
        chineseName: '特克斯和凯科斯群岛',
        englishName: 'Turks & Caicos Islands',
        jaName: 'タークスカイコス諸島',
        twoLetterCode: 'TC',
        phoneCode: '+1649',
      },
      {
        countryId: 384,
        chineseName: '特立尼达和多巴哥',
        englishName: 'Trinidad & Tobago',
        jaName: 'トリニダードトバゴ',
        twoLetterCode: 'TT',
        phoneCode: '+1868',
      },
      {
        countryId: 358,
        chineseName: '危地马拉',
        englishName: 'Guatemala',
        jaName: 'グアテマラ',
        twoLetterCode: 'GT',
        phoneCode: '+502',
      },
      {
        countryId: 356,
        chineseName: '委内瑞拉',
        englishName: 'Venezuela',
        jaName: 'ベネズエラ',
        twoLetterCode: 'VE',
        phoneCode: '+58',
      },
      {
        countryId: 350,
        chineseName: '乌拉圭',
        englishName: 'Uruguay',
        jaName: 'ウルグアイ',
        twoLetterCode: 'UY',
        phoneCode: '+598',
      },
      {
        countryId: 342,
        chineseName: '牙买加',
        englishName: 'Jamaica',
        jaName: 'ジャマイカ',
        twoLetterCode: 'JM',
        phoneCode: '+1876',
      },
      {
        countryId: 388,
        chineseName: '英属维尔京群岛',
        englishName: 'British Virgin Islands',
        jaName: 'イギリス領ヴァージン諸島',
        twoLetterCode: 'VG',
        phoneCode: '+1284',
      },
      {
        countryId: 340,
        chineseName: '智利',
        englishName: 'Chile',
        jaName: 'チリ',
        twoLetterCode: 'CL',
        phoneCode: '+56',
      },
    ],
  },
];

export const areaCountryOpts = (locale: string) => {
  const areaKey = lg.areaCountryOneMap[locale] as keyof AreaLgName;
  const countryKey = lg.areaCountryTwoMap[locale] as keyof CountryPropsLgName;

  return areaCountryList.map((area) => ({
    value: area[areaKey],
    label: area[areaKey],
    children: area.countryList
      .filter((item) => item.phoneCode)
      .map((country) => ({
        value: country.countryId,
        label: country[countryKey],
      })),
  }));
};

//获得key为id  value为不同语言名的对象
const getIdAndNameCountryObj = (): Record<number, any> => {
  let countryObj: Record<number, any> = {};
  areaCountryList.forEach((area) => {
    area.countryList.forEach((item) => {
      countryObj[item.countryId] = item;
    });
  });
  return countryObj;
};
export const idAndNameCountryObj = getIdAndNameCountryObj();

const getCountryCode2NameMap = () => {
  const res: Record<string, CountryProps> = {};

  areaCountryList.forEach((a) => {
    a.countryList.forEach((c) => {
      res[c.twoLetterCode] = c;
    });
  });

  return res;
};

export const countryCode2NameMap = getCountryCode2NameMap();

const getCountryCode2IdMap = () => {
  const res: Record<string, number> = {};

  areaCountryList.forEach((a) => {
    a.countryList.forEach((c) => {
      res[c.twoLetterCode] = c.countryId;
    });
  });

  return res;
};

const getId2CountryCodeMap = () => {
  const res: Record<number, string> = {};

  areaCountryList.forEach((a) => {
    a.countryList.forEach((c) => {
      res[c.countryId] = c.twoLetterCode;
    });
  });

  return res;
};

export const countryCode2IdMap = getCountryCode2IdMap();
export const id2CountryCodeMap = getId2CountryCodeMap();
export interface IOptions {
  value: string;
  label: string;
  labelEn: string;
  labelJa: string;
}
// 语言代码，参考https://note.youdao.com/ynoteshare1/index.html?id=b3c002e2e1897097e4c8e33bce1318b8&type=note
export const LanguageCode: IOptions[] = [
  { label: '简体中文', value: 'zh-CHS', labelEn: 'Simplified Chinese', labelJa: '簡体中国語' },
  { label: '繁体中文', value: 'zh-CHT', labelEn: 'Traditional Chinese', labelJa: '繁体中国語' },
  { label: '英文', value: 'en', labelEn: 'English', labelJa: '英語' },
  { label: '日文', value: 'ja', labelEn: 'Japanese', labelJa: '日本語' },
  { label: '韩文', value: 'ko', labelEn: 'Korean', labelJa: '한국어' },
  { label: '法文', value: 'fr', labelEn: 'French', labelJa: 'フランス語' },
  { label: '西班牙文', value: 'es', labelEn: 'Spanish', labelJa: 'スペイン語' },
  { label: '葡萄牙文', value: 'pt', labelEn: 'Portuguese', labelJa: 'ポルトガル語' },
  { label: '意大利文', value: 'it', labelEn: 'Italian', labelJa: 'イタリア語' },
  { label: '俄文', value: 'ru', labelEn: 'Russian', labelJa: 'ロシア語' },
  { label: '越南文', value: 'vi', labelEn: 'Vietnamese', labelJa: 'ベトナム語' },
  { label: '德文', value: 'de', labelEn: 'German', labelJa: 'ドイツ語' },
  { label: '阿拉伯文', value: 'ar', labelEn: 'Arabic', labelJa: 'アラビア語' },
  { label: '印尼文', value: 'id', labelEn: 'Indonesian', labelJa: 'インドネシア語' },
  { label: '南非荷兰语', value: 'af', labelEn: 'Afrikaans', labelJa: 'アフリカーンス語' },
  { label: '波斯尼亚语', value: 'bs', labelEn: 'Bosnian', labelJa: 'ボスニア語' },
  { label: '保加利亚语', value: 'bg', labelEn: 'Bulgarian', labelJa: 'ブルガリア語' },
  { label: '粤语', value: 'yue', labelEn: 'Cantonese', labelJa: '広東語（ユエー語）' },
  { label: '加泰隆语', value: 'ca', labelEn: 'Catalan', labelJa: 'カタルーニャ語' },
  { label: '克罗地亚语', value: 'hr', labelEn: 'Croatian', labelJa: 'クロアチア語' },
  { label: '捷克语', value: 'cs', labelEn: 'Czech', labelJa: 'チェコ語' },
  { label: '丹麦语', value: 'da', labelEn: 'Danish', labelJa: 'デンマーク語' },
  { label: '荷兰语', value: 'nl', labelEn: 'Dutch', labelJa: 'オランダ語' },
  { label: '爱沙尼亚语', value: 'et', labelEn: 'Estonian', labelJa: 'エストニア語' },
  { label: '斐济语', value: 'fj', labelEn: 'Fijian', labelJa: 'フィジー語' },
  { label: '芬兰语', value: 'fi', labelEn: 'Finnish', labelJa: 'フィンランド語' },
  { label: '希腊语', value: 'el', labelEn: 'Greek', labelJa: 'ギリシャ語' },
  { label: '海地克里奥尔语', value: 'ht', labelEn: 'Haitian Creole', labelJa: 'ハイチアン・クレオール語' },
  { label: '希伯来语', value: 'he', labelEn: 'Hebrew', labelJa: 'ヘブライ語' },
  { label: '印地语', value: 'hi', labelEn: 'Hindi', labelJa: 'ヒンディー語' },
  { label: '白苗语', value: 'mww', labelEn: 'Hmong', labelJa: 'ホンミャオ語' },
  { label: '匈牙利语', value: 'hu', labelEn: 'Hungarian', labelJa: 'ハンガリー語' },
  { label: '斯瓦希里语', value: 'sw', labelEn: 'Swahili', labelJa: 'スワヒリ語' },
  { label: '克林贡语', value: 'tlh', labelEn: 'Klingon', labelJa: 'クリンゴン語' },
  { label: '拉脱维亚语', value: 'lv', labelEn: 'Latvian', labelJa: 'ラトビア語' },
  { label: '立陶宛语', value: 'lt', labelEn: 'Lithuanian', labelJa: 'リトアニア語' },
  { label: '马来语', value: 'ms', labelEn: 'Malay', labelJa: 'マレー語' },
  { label: '马耳他语', value: 'mt', labelEn: 'Maltese', labelJa: 'マルタ語' },
  { label: '挪威语', value: 'no', labelEn: 'Norwegian', labelJa: 'ノルウェー語' },
  { label: '波斯语', value: 'fa', labelEn: 'Persian', labelJa: 'ペルシア語' },
  { label: '波兰语', value: 'pl', labelEn: 'Polish', labelJa: 'ポーランド語' },
  { label: '克雷塔罗奥托米语', value: 'otq', labelEn: 'Queretaro Otomi', labelJa: 'ケレタロ・オトミ語' },
  { label: '罗马尼亚语', value: 'ro', labelEn: 'Romanian', labelJa: 'ルーマニア語' },
  {
    label: '塞尔维亚语(西里尔文)',
    value: 'sr-Cyrl',
    labelEn: 'Serbian (Cyrillic)',
    labelJa: 'セルビア語（キリル文字）',
  },
  { label: '塞尔维亚语(拉丁文)', value: 'sr-Latn', labelEn: 'Serbian (Latin)', labelJa: 'セルビア語（ラテン文字）' },
  { label: '斯洛伐克语', value: 'sk', labelEn: 'Slovak', labelJa: 'スロバキア語' },
  { label: '斯洛文尼亚语', value: 'sl', labelEn: 'Slovenian', labelJa: 'スロベニア語' },
  { label: '瑞典语', value: 'sv', labelEn: 'Swedish', labelJa: 'スウェーデン語' },
  { label: '塔希提语', value: 'ty', labelEn: 'Tahitian', labelJa: 'タヒティ語' },
  { label: '泰语', value: 'th', labelEn: 'Thai', labelJa: 'タイ語' },
  { label: '汤加语', value: 'to', labelEn: 'Tongan', labelJa: 'トンガ語' },
  { label: '土耳其语', value: 'tr', labelEn: 'Turkish', labelJa: 'トルコ語' },
  { label: '乌克兰语', value: 'uk', labelEn: 'Ukrainian', labelJa: 'ウクライナ語' },
  { label: '乌尔都语', value: 'ur', labelEn: 'Urdu', labelJa: 'ウルドゥー語' },
  { label: '威尔士语', value: 'cy', labelEn: 'Welsh', labelJa: 'ウェールズ語' },
  { label: '尤卡坦玛雅语', value: 'yua', labelEn: 'Yucatán Maya', labelJa: 'ユカタン・マヤ語' },
  { label: '阿尔巴尼亚语', value: 'sq', labelEn: 'Albanian', labelJa: 'アルバニア語' },
  { label: '阿姆哈拉语', value: 'am', labelEn: 'Amharic', labelJa: 'アムハラ語' },
  { label: '亚美尼亚语', value: 'hy', labelEn: 'Armenian', labelJa: 'アルメニア語' },
  { label: '阿塞拜疆语', value: 'az', labelEn: 'Azerbaijani', labelJa: 'アゼルバイジャン語' },
  { label: '孟加拉语', value: 'bn', labelEn: 'Bengali', labelJa: 'ベンガル語' },
  { label: '巴斯克语', value: 'eu', labelEn: 'Basque', labelJa: 'バスク語' },
  { label: '白俄罗斯语', value: 'be', labelEn: 'Belarusian', labelJa: 'ベラルーシ語' },
  { label: '宿务语', value: 'ceb', labelEn: 'Cebuano', labelJa: 'セブアノ語' },
  { label: '科西嘉语', value: 'co', labelEn: 'Corsican', labelJa: 'コルシカ語' },
  { label: '世界语', value: 'eo', labelEn: 'Esperanto', labelJa: 'エスペラント語' },
  { label: '菲律宾语', value: 'tl', labelEn: 'Filipino', labelJa: 'フィリピノ語' },
  { label: '弗里西语', value: 'fy', labelEn: 'Frisian', labelJa: 'フリジア語' },
  { label: '加利西亚语', value: 'gl', labelEn: 'Galician', labelJa: 'ガリシア語' },
  { label: '格鲁吉亚语', value: 'ka', labelEn: 'Georgian', labelJa: 'グルジア語' },
  { label: '古吉拉特语', value: 'gu', labelEn: 'Gujarati', labelJa: 'グジャラート語' },
  { label: '豪萨语', value: 'ha', labelEn: 'Hausa', labelJa: 'ハウサ語' },
  { label: '夏威夷语', value: 'haw', labelEn: 'Hawaiian', labelJa: 'ハワイ語' },
  { label: '冰岛语', value: 'is', labelEn: 'Icelandic', labelJa: 'アイスランド語' },
  { label: '伊博语', value: 'ig', labelEn: 'Igbo', labelJa: 'イボ語' },
  { label: '爱尔兰语', value: 'ga', labelEn: 'Irish', labelJa: 'アイルランド語' },
  { label: '爪哇语', value: 'jw', labelEn: 'Javanese', labelJa: 'ジャワ語' },
  { label: '卡纳达语', value: 'kn', labelEn: 'Kannada', labelJa: 'カナダ語' },
  { label: '哈萨克语', value: 'kk', labelEn: 'Kazakh', labelJa: 'カザフ語' },
  { label: '高棉语', value: 'km', labelEn: 'Khmer', labelJa: 'コンボジア語' },
  { label: '库尔德语', value: 'ku', labelEn: 'Kurdish', labelJa: 'クルド語' },
  { label: '柯尔克孜语', value: 'ky', labelEn: 'Kirgiz', labelJa: 'キルギス語' },
  { label: '老挝语', value: 'lo', labelEn: 'Lao', labelJa: 'ラオス語' },
  { label: '拉丁语', value: 'la', labelEn: 'Latin', labelJa: 'ラテン語' },
  { label: '卢森堡语', value: 'lb', labelEn: 'Luxembourgish', labelJa: 'ルクセンブルク語' },
  { label: '马其顿语', value: 'mk', labelEn: 'Macedonian', labelJa: 'マケドニア語' },
  { label: '马尔加什语', value: 'mg', labelEn: 'Malagasy', labelJa: 'マダガスカル語' },
  { label: '马拉雅拉姆语', value: 'ml', labelEn: 'Malayalam', labelJa: 'マラヤーラム語' },
  { label: '毛利语', value: 'mi', labelEn: 'Maori', labelJa: 'マオリ語' },
  { label: '马拉地语', value: 'mr', labelEn: 'Marathi', labelJa: 'マラーティー語' },
  { label: '蒙古语', value: 'mn', labelEn: 'Mongolian', labelJa: 'モンゴル語' },
  { label: '缅甸语', value: 'my', labelEn: 'Burmese', labelJa: 'ビルマ語' },
  { label: '尼泊尔语', value: 'ne', labelEn: 'Nepali', labelJa: 'ネパール語' },
  { label: '齐切瓦语', value: 'ny', labelEn: 'Chichewa', labelJa: 'チチェワ語' },
  { label: '普什图语', value: 'ps', labelEn: 'Pashto', labelJa: 'パシュト語' },
  { label: '旁遮普语', value: 'pa', labelEn: 'Punjabi', labelJa: 'パンジャビ語' },
  { label: '萨摩亚语', value: 'sm', labelEn: 'Samoan', labelJa: 'サモア語' },
  { label: '苏格兰盖尔语', value: 'gd', labelEn: 'Scottish Gaelic', labelJa: 'スコットランド・ゲール語' },
  { label: '塞索托语', value: 'st', labelEn: 'Sesotho', labelJa: 'セソト語' },
  { label: '修纳语', value: 'sn', labelEn: 'Shona', labelJa: 'ショナ語' },
  { label: '信德语', value: 'sd', labelEn: 'Sindhi', labelJa: 'シンド語' },
  { label: '僧伽罗语', value: 'si', labelEn: 'Sinhala', labelJa: 'シンハラ語' },
  { label: '索马里语', value: 'so', labelEn: 'Somali', labelJa: 'ソマリ語' },
  { label: '巽他语', value: 'su', labelEn: 'Sundanese', labelJa: 'スンダ語' },
  { label: '塔吉克语', value: 'tg', labelEn: 'Tajik', labelJa: 'タジク語' },
  { label: '泰米尔语', value: 'ta', labelEn: 'Tamil', labelJa: 'タミル語' },
  { label: '泰卢固语', value: 'te', labelEn: 'Telugu', labelJa: 'テルグ語' },
  { label: '乌兹别克语', value: 'uz', labelEn: 'Uzbek', labelJa: 'ウズベク語' },
  { label: '南非科萨语', value: 'xh', labelEn: 'South African Xhosa', labelJa: '南アフリカのコサ語' },
  { label: '意第绪语', value: 'yi', labelEn: 'Yiddish', labelJa: 'イディッシュ語' },
  { label: '约鲁巴语', value: 'yo', labelEn: 'Yoruba', labelJa: 'ヨルバ語' },
  { label: '南非祖鲁语', value: 'zu', labelEn: 'Zulu (South Africa)', labelJa: '南アフリカのズールー語' },
];

// 国家 ISO表
export const Country: Record<string, any> = {
  PH: {
    'en-US': 'Philippines',
    'zh-CN': '菲律宾',
    'ja-JP': 'フィリピン',
  },
  MY: {
    'en-US': 'Malaysia',
    'zh-CN': '马来西亚',
    'ja-JP': 'マレーシア',
  },
  TH: {
    'en-US': 'Thailand',
    'zh-CN': '泰国',
    'ja-JP': 'タイ',
  },
  SG: {
    'en-US': 'Singapore',
    'zh-CN': '新加坡',
    'ja-JP': 'シンガポール',
  },
  ID: {
    'en-US': 'Indonesia',
    'zh-CN': '印度尼西亚',
    'ja-JP': 'インドネシア',
  },
  VN: {
    'en-US': 'Vietnam',
    'zh-CN': '越南',
    'ja-JP': 'ベトナム',
  },
  TL: {
    'en-US': 'Timor-Leste',
    'zh-CN': '东帝汶',
    'ja-JP': '東ティモール',
  },
  KH: {
    'en-US': 'Cambodia',
    'zh-CN': '柬埔寨',
    'ja-JP': 'カンボジア',
  },
  LA: {
    'en-US': 'Laos',
    'zh-CN': '老挝',
    'ja-JP': 'ラオス',
  },
  MM: {
    'en-US': 'Myanmar (Burma)',
    'zh-CN': '缅甸',
    'ja-JP': 'ミャンマー',
  },
  BN: {
    'en-US': 'Brunei',
    'zh-CN': '文莱',
    'ja-JP': 'ブルネイ',
  },
  AU: {
    'en-US': 'Australia',
    'zh-CN': '澳大利亚',
    'ja-JP': 'オーストラリア',
  },
  NZ: {
    'en-US': 'New Zealand',
    'zh-CN': '新西兰',
    'ja-JP': 'ニュージーランド',
  },
  PG: {
    'en-US': 'Papua New Guinea',
    'zh-CN': '巴布亚新几内亚',
    'ja-JP': 'パプアニューギニア',
  },
  MP: {
    'en-US': 'Northern Mariana Islands',
    'zh-CN': '北马里亚纳',
    'ja-JP': '北マリアナ',
  },
  PF: {
    'en-US': 'French Polynesia',
    'zh-CN': '法属波利尼西亚',
    'ja-JP': 'フランス領ポリネシア',
  },
  FJ: {
    'en-US': 'Fiji',
    'zh-CN': '斐济',
    'ja-JP': 'フィジー',
  },
  GU: {
    'en-US': 'Guam',
    'zh-CN': '关岛',
    'ja-JP': 'グアム',
  },
  KI: {
    'en-US': 'Kiribati',
    'zh-CN': '基里巴斯',
    'ja-JP': 'キリバス',
  },
  CK: {
    'en-US': 'Cook Islands',
    'zh-CN': '库克群岛',
    'ja-JP': 'クック諸島',
  },
  MH: {
    'en-US': 'Marshall Islands',
    'zh-CN': '马绍尔群岛',
    'ja-JP': 'マーシャル諸島',
  },
  AS: {
    'en-US': 'American Samoa',
    'zh-CN': '美属萨摩亚',
    'ja-JP': 'アメリカ領サモア',
  },
  FM: {
    'en-US': 'Micronesia',
    'zh-CN': '密克罗尼西亚联邦',
    'ja-JP': 'ミクロネシア連邦',
  },
  NR: {
    'en-US': 'Nauru',
    'zh-CN': '瑙鲁',
    'ja-JP': 'ナウル',
  },
  NU: {
    'en-US': 'Niue',
    'zh-CN': '纽埃',
    'ja-JP': 'ニウエ',
  },
  PW: {
    'en-US': 'Palau',
    'zh-CN': '帕劳',
    'ja-JP': 'パラオ',
  },
  PN: {
    'en-US': 'Pitcairn Islands',
    'zh-CN': '皮特凯恩',
    'ja-JP': 'ピトケアン',
  },
  WS: {
    'en-US': 'Samoa',
    'zh-CN': '萨摩亚',
    'ja-JP': 'サモア',
  },
  SB: {
    'en-US': 'Solomon Islands',
    'zh-CN': '所罗门群岛',
    'ja-JP': 'ソロモン諸島',
  },
  TO: {
    'en-US': 'Tonga',
    'zh-CN': '汤加',
    'ja-JP': 'トンガ',
  },
  TV: {
    'en-US': 'Tuvalu',
    'zh-CN': '图瓦卢',
    'ja-JP': 'ツバル',
  },
  TK: {
    'en-US': 'Tokelau',
    'zh-CN': '托克劳',
    'ja-JP': 'トケラウ',
  },
  WF: {
    'en-US': 'Wallis & Futuna',
    'zh-CN': '瓦利斯和富图纳',
    'ja-JP': 'ワリスとフツナ',
  },
  VU: {
    'en-US': 'Vanuatu',
    'zh-CN': '瓦努阿图',
    'ja-JP': 'バヌアツ',
  },
  NC: {
    'en-US': 'New Caledonia',
    'zh-CN': '新喀里多尼亚',
    'ja-JP': 'ニューカレドニア',
  },
  RU: {
    'en-US': 'Russia',
    'zh-CN': '俄罗斯',
    'ja-JP': 'ロシア',
  },
  UA: {
    'en-US': 'Ukraine',
    'zh-CN': '乌克兰',
    'ja-JP': 'ウクライナ',
  },
  BY: {
    'en-US': 'Belarus',
    'zh-CN': '白俄罗斯',
    'ja-JP': 'ベラルーシ',
  },
  KZ: {
    'en-US': 'Kazakhstan',
    'zh-CN': '哈萨克斯坦',
    'ja-JP': 'カザフスタン',
  },
  KG: {
    'en-US': 'Kyrgyzstan',
    'zh-CN': '吉尔吉斯斯坦',
    'ja-JP': 'キルギス',
  },
  MD: {
    'en-US': 'Moldova',
    'zh-CN': '摩尔多瓦',
    'ja-JP': 'モルドバ',
  },
  TJ: {
    'en-US': 'Tajikistan',
    'zh-CN': '塔吉克斯坦',
    'ja-JP': 'タジキスタン',
  },
  TM: {
    'en-US': 'Turkmenistan',
    'zh-CN': '土库曼斯坦',
    'ja-JP': 'トルクメニスタン',
  },
  UZ: {
    'en-US': 'Uzbekistan',
    'zh-CN': '乌兹别克斯坦',
    'ja-JP': 'ウズベキスタン',
  },
  IE: {
    'en-US': 'Ireland',
    'zh-CN': '爱尔兰',
    'ja-JP': 'アイルランド',
  },
  AT: {
    'en-US': 'Austria',
    'zh-CN': '奥地利',
    'ja-JP': 'オーストリア',
  },
  BE: {
    'en-US': 'Belgium',
    'zh-CN': '比利时',
    'ja-JP': 'ベルギー',
  },
  IS: {
    'en-US': 'Iceland',
    'zh-CN': '冰岛',
    'ja-JP': 'アイスランド',
  },
  DK: {
    'en-US': 'Denmark',
    'zh-CN': '丹麦',
    'ja-JP': 'デンマーク',
  },
  DE: {
    'en-US': 'Germany',
    'zh-CN': '德国',
    'ja-JP': 'ドイツ',
  },
  FR: {
    'en-US': 'France',
    'zh-CN': '法国',
    'ja-JP': 'フランス',
  },
  FI: {
    'en-US': 'Finland',
    'zh-CN': '芬兰',
    'ja-JP': 'フィンランド',
  },
  NL: {
    'en-US': 'Netherlands',
    'zh-CN': '荷兰',
    'ja-JP': 'オランダ',
  },
  NO: {
    'en-US': 'Norway',
    'zh-CN': '挪威',
    'ja-JP': 'ノルウェー',
  },
  PT: {
    'en-US': 'Portugal',
    'zh-CN': '葡萄牙',
    'ja-JP': 'ポルトガル',
  },
  SE: {
    'en-US': 'Sweden',
    'zh-CN': '瑞典',
    'ja-JP': 'スウェーデン',
  },
  CH: {
    'en-US': 'Switzerland',
    'zh-CN': '瑞士',
    'ja-JP': 'スイス',
  },
  ES: {
    'en-US': 'Spain',
    'zh-CN': '西班牙',
    'ja-JP': 'スペイン',
  },
  GR: {
    'en-US': 'Greece',
    'zh-CN': '希腊',
    'ja-JP': 'ギリシャ',
  },
  IT: {
    'en-US': 'Italy',
    'zh-CN': '意大利',
    'ja-JP': 'イタリア',
  },
  GB: {
    'en-US': 'United Kingdom',
    'zh-CN': '英国',
    'ja-JP': 'イギリス',
  },
  AL: {
    'en-US': 'Albania',
    'zh-CN': '阿尔巴尼亚',
    'ja-JP': 'アルバニア',
  },
  EE: {
    'en-US': 'Estonia',
    'zh-CN': '爱沙尼亚',
    'ja-JP': 'エストニア',
  },
  AD: {
    'en-US': 'Andorra',
    'zh-CN': '安道尔',
    'ja-JP': 'アンドラ',
  },
  BG: {
    'en-US': 'Bulgaria',
    'zh-CN': '保加利亚',
    'ja-JP': 'ブルガリア',
  },
  BA: {
    'en-US': 'Bosnia & Herzegovina',
    'zh-CN': '波黑',
    'ja-JP': 'ボスニア・ヘルツェゴビナ',
  },
  PL: {
    'en-US': 'Poland',
    'zh-CN': '波兰',
    'ja-JP': 'ポーランド',
  },
  FO: {
    'en-US': 'Faroe Islands',
    'zh-CN': '法罗群岛',
    'ja-JP': 'フェロー諸島',
  },
  VA: {
    'en-US': 'Vatican City',
    'zh-CN': '梵蒂冈',
    'ja-JP': 'バチカン',
  },
  GG: {
    'en-US': 'Guernsey',
    'zh-CN': '根西岛',
    'ja-JP': 'ガーンジー',
  },
  ME: {
    'en-US': 'Montenegro',
    'zh-CN': '黑山',
    'ja-JP': 'モンテネグロ',
  },
  CZ: {
    'en-US': 'Czechia',
    'zh-CN': '捷克',
    'ja-JP': 'チェコ',
  },
  HR: {
    'en-US': 'Croatia',
    'zh-CN': '克罗地亚',
    'ja-JP': 'クロアチア',
  },
  LV: {
    'en-US': 'Latvia',
    'zh-CN': '拉脱维亚',
    'ja-JP': 'ラトビア',
  },
  LT: {
    'en-US': 'Lithuania',
    'zh-CN': '立陶宛',
    'ja-JP': 'リトアニア',
  },
  LI: {
    'en-US': 'Liechtenstein',
    'zh-CN': '列支敦士登',
    'ja-JP': 'リヒテンシュタイン',
  },
  LU: {
    'en-US': 'Luxembourg',
    'zh-CN': '卢森堡',
    'ja-JP': 'ルクセンブルク',
  },
  RO: {
    'en-US': 'Romania',
    'zh-CN': '罗马尼亚',
    'ja-JP': 'ルーマニア',
  },
  IM: {
    'en-US': 'Isle of man',
    'zh-CN': '马恩岛',
    'ja-JP': 'マン島',
  },
  MT: {
    'en-US': 'Malta',
    'zh-CN': '马耳他',
    'ja-JP': 'マルタ',
  },
  YT: {
    'en-US': 'Mayotte',
    'zh-CN': '马约特',
    'ja-JP': 'マヨット',
  },
  MC: {
    'en-US': 'Monaco',
    'zh-CN': '摩纳哥',
    'ja-JP': 'モナコ',
  },
  MK: {
    'en-US': 'North Macedonia',
    'zh-CN': '前南马其顿',
    'ja-JP': '北マケドニア',
  },
  RS: {
    'en-US': 'Serbia',
    'zh-CN': '塞尔维亚',
    'ja-JP': 'セルビア',
  },
  SM: {
    'en-US': 'San Marino',
    'zh-CN': '圣马力诺',
    'ja-JP': 'サンマリノ',
  },
  SK: {
    'en-US': 'Slovakia',
    'zh-CN': '斯洛伐克',
    'ja-JP': 'スロバキア',
  },
  SI: {
    'en-US': 'Slovenia',
    'zh-CN': '斯洛文尼亚',
    'ja-JP': 'スロベニア',
  },
  HU: {
    'en-US': 'Hungary',
    'zh-CN': '匈牙利',
    'ja-JP': 'ハンガリー',
  },
  JE: {
    'en-US': 'Jersey',
    'zh-CN': '泽西岛',
    'ja-JP': 'ジャージー',
  },
  GI: {
    'en-US': 'Gibraltar',
    'zh-CN': '直布罗陀',
    'ja-JP': 'ジブラルタル',
  },
  EG: {
    'en-US': 'Egypt',
    'zh-CN': '埃及',
    'ja-JP': 'エジプト',
  },
  ZA: {
    'en-US': 'South Africa',
    'zh-CN': '南非',
    'ja-JP': '南アフリカ',
  },
  DZ: {
    'en-US': 'Algeria',
    'zh-CN': '阿尔及利亚',
    'ja-JP': 'アルジェリア',
  },
  ET: {
    'en-US': 'Ethiopia',
    'zh-CN': '埃塞俄比亚',
    'ja-JP': 'エチオピア',
  },
  AO: {
    'en-US': 'Angola',
    'zh-CN': '安哥拉',
    'ja-JP': 'アンゴラ',
  },
  BJ: {
    'en-US': 'Benin',
    'zh-CN': '贝宁',
    'ja-JP': 'ベナン',
  },
  BW: {
    'en-US': 'Botswana',
    'zh-CN': '博茨瓦纳',
    'ja-JP': 'ボツワナ',
  },
  BF: {
    'en-US': 'Burkina Faso',
    'zh-CN': '布基纳法索',
    'ja-JP': 'ブルキナファソ',
  },
  BI: {
    'en-US': 'Burundi',
    'zh-CN': '布隆迪',
    'ja-JP': 'ブルンジ',
  },
  GQ: {
    'en-US': 'Equatorial Guinea',
    'zh-CN': '赤道几内亚',
    'ja-JP': '赤道ギニア',
  },
  TG: {
    'en-US': 'Togo',
    'zh-CN': '多哥',
    'ja-JP': 'トーゴ',
  },
  ER: {
    'en-US': 'Eritrea',
    'zh-CN': '厄立特里亚',
    'ja-JP': 'エリトリア',
  },
  CV: {
    'en-US': 'Cape Verde',
    'zh-CN': '佛得角',
    'ja-JP': 'カーボベルデ',
  },
  GM: {
    'en-US': 'Gambia',
    'zh-CN': '冈比亚',
    'ja-JP': 'ガンビア',
  },
  CD: {
    'en-US': 'Democratic Republic of the Congo',
    'zh-CN': '刚果民主共和国',
    'ja-JP': 'コンゴ民主共和国',
  },
  DJ: {
    'en-US': 'Djibouti',
    'zh-CN': '吉布提',
    'ja-JP': 'ジブチ',
  },
  GN: {
    'en-US': 'Guinea',
    'zh-CN': '几内亚',
    'ja-JP': 'ギニア',
  },
  GW: {
    'en-US': 'Guinea-Bissau',
    'zh-CN': '几内亚比绍',
    'ja-JP': 'ギニアビサウ',
  },
  XA: {
    'en-US': 'Pseudo-Accents',
    'zh-CN': '加那利群岛',
    'ja-JP': 'カナリア諸島',
  },
  GH: {
    'en-US': 'Ghana',
    'zh-CN': '加纳',
    'ja-JP': 'ガーナ',
  },
  GA: {
    'en-US': 'Gabon',
    'zh-CN': '加蓬',
    'ja-JP': 'ガボン',
  },
  ZW: {
    'en-US': 'Zimbabwe',
    'zh-CN': '津巴布韦',
    'ja-JP': 'ジンバブエ',
  },
  CM: {
    'en-US': 'Cameroon',
    'zh-CN': '喀麦隆',
    'ja-JP': 'カメルーン',
  },
  KM: {
    'en-US': 'Comoros',
    'zh-CN': '科摩罗',
    'ja-JP': 'コモロ',
  },
  CI: {
    'en-US': 'Côte d’Ivoire',
    'zh-CN': '科特迪瓦',
    'ja-JP': 'コートジボワール',
  },
  KE: {
    'en-US': 'Kenya',
    'zh-CN': '肯尼亚',
    'ja-JP': 'ケニア',
  },
  LS: {
    'en-US': 'Lesotho',
    'zh-CN': '莱索托',
    'ja-JP': 'レソト',
  },
  LR: {
    'en-US': 'Liberia',
    'zh-CN': '利比里亚',
    'ja-JP': 'リベリア',
  },
  LY: {
    'en-US': 'Libya',
    'zh-CN': '利比亚',
    'ja-JP': 'リビア',
  },
  RE: {
    'en-US': 'Réunion',
    'zh-CN': '留尼汪',
    'ja-JP': 'レユニオン',
  },
  RW: {
    'en-US': 'Rwanda',
    'zh-CN': '卢旺达',
    'ja-JP': 'ルワンダ',
  },
  MG: {
    'en-US': 'Madagascar',
    'zh-CN': '马达加斯加',
    'ja-JP': 'マダガスカル',
  },
  XI: {
    'en-US': 'Madeira',
    'zh-CN': '马德拉',
    'ja-JP': 'マデイラ',
  },
  MW: {
    'en-US': 'Malawi',
    'zh-CN': '马拉维',
    'ja-JP': 'マラウイ',
  },
  ML: {
    'en-US': 'Mali',
    'zh-CN': '马里',
    'ja-JP': 'マリ',
  },
  MU: {
    'en-US': 'Mauritius',
    'zh-CN': '毛里求斯',
    'ja-JP': 'モーリシャス',
  },
  MR: {
    'en-US': 'Mauritania',
    'zh-CN': '毛利塔尼亚',
    'ja-JP': 'モーリタニア',
  },
  MA: {
    'en-US': 'Morocco',
    'zh-CN': '摩洛哥',
    'ja-JP': 'モロッコ',
  },
  MZ: {
    'en-US': 'Mozambique',
    'zh-CN': '莫桑比克',
    'ja-JP': 'モザンビーク',
  },
  NA: {
    'en-US': 'Namibia',
    'zh-CN': '纳米比亚',
    'ja-JP': 'ナミビア',
  },
  SS: {
    'en-US': 'South Sudan',
    'zh-CN': '南苏丹',
    'ja-JP': '南スーダン',
  },
  NE: {
    'en-US': 'Niger',
    'zh-CN': '尼日尔',
    'ja-JP': 'ニジェール',
  },
  NG: {
    'en-US': 'Nigeria',
    'zh-CN': '尼日利亚',
    'ja-JP': 'ナイジェリア',
  },
  SL: {
    'en-US': 'Sierra Leone',
    'zh-CN': '塞拉利昂',
    'ja-JP': 'シエラレオネ',
  },
  SN: {
    'en-US': 'Senegal',
    'zh-CN': '塞内加尔',
    'ja-JP': 'セネガル',
  },
  SC: {
    'en-US': 'Seychelles',
    'zh-CN': '塞舌尔',
    'ja-JP': 'セーシェル',
  },
  ST: {
    'en-US': 'São Tomé & Príncipe',
    'zh-CN': '圣多美和普林西比',
    'ja-JP': 'サントメ・プリンシペ',
  },
  SH: {
    'en-US': 'St. Helena',
    'zh-CN': '圣赫勒拿',
    'ja-JP': 'セントヘレナ',
  },
  SZ: {
    'en-US': 'Eswatini',
    'zh-CN': '斯威士兰',
    'ja-JP': 'スワジランド',
  },
  SD: {
    'en-US': 'Sudan',
    'zh-CN': '苏丹',
    'ja-JP': 'スーダン',
  },
  SO: {
    'en-US': 'Somalia',
    'zh-CN': '索马里',
    'ja-JP': 'ソマリア',
  },
  TZ: {
    'en-US': 'Tanzania',
    'zh-CN': '坦桑尼亚',
    'ja-JP': 'タンザニア',
  },
  TN: {
    'en-US': 'Tunisia',
    'zh-CN': '突尼斯',
    'ja-JP': 'チュニジア',
  },
  UG: {
    'en-US': 'Uganda',
    'zh-CN': '乌干达',
    'ja-JP': 'ウガンダ',
  },
  EH: {
    'en-US': 'Western Sahara',
    'zh-CN': '西撒哈拉',
    'ja-JP': '西サハラ',
  },
  XH: {
    'en-US': 'Azores',
    'zh-CN': '亚速尔',
    'ja-JP': 'アゾレス',
  },
  ZM: {
    'en-US': 'Zambia',
    'zh-CN': '赞比亚',
    'ja-JP': 'ザンビア',
  },
  TD: {
    'en-US': 'Chad',
    'zh-CN': '乍得',
    'ja-JP': 'チャド',
  },
  AE: {
    'en-US': 'United Arab Emirates',
    'zh-CN': '阿联酋',
    'ja-JP': 'アラブ首長国連邦',
  },
  SA: {
    'en-US': 'Saudi Arabia',
    'zh-CN': '沙特阿拉伯',
    'ja-JP': 'サウジアラビア',
  },
  TR: {
    'en-US': 'Turkey',
    'zh-CN': '土耳其',
    'ja-JP': 'トルコ',
  },
  IR: {
    'en-US': 'Iran',
    'zh-CN': '伊朗',
    'ja-JP': 'イラン',
  },
  IL: {
    'en-US': 'Israel',
    'zh-CN': '以色列',
    'ja-JP': 'イスラエル',
  },
  AF: {
    'en-US': 'Afghanistan',
    'zh-CN': '阿富汗',
    'ja-JP': 'アフガニスタン',
  },
  OM: {
    'en-US': 'Oman',
    'zh-CN': '阿曼',
    'ja-JP': 'オマーン',
  },
  AZ: {
    'en-US': 'Azerbaijan',
    'zh-CN': '阿塞拜疆',
    'ja-JP': 'アゼルバイジャン',
  },
  PS: {
    'en-US': 'Palestinian Territories',
    'zh-CN': '巴勒斯坦',
    'ja-JP': 'パレスチナ',
  },
  BH: {
    'en-US': 'Bahrain',
    'zh-CN': '巴林',
    'ja-JP': 'バーレーン',
  },
  GE: {
    'en-US': 'Georgia',
    'zh-CN': '格鲁吉亚',
    'ja-JP': 'ジョージア',
  },
  QA: {
    'en-US': 'Qatar',
    'zh-CN': '卡塔尔',
    'ja-JP': 'カタール',
  },
  KW: {
    'en-US': 'Kuwait',
    'zh-CN': '科威特',
    'ja-JP': 'クウェート',
  },
  LB: {
    'en-US': 'Lebanon',
    'zh-CN': '黎巴嫩',
    'ja-JP': 'レバノン',
  },
  CY: {
    'en-US': 'Cyprus',
    'zh-CN': '塞浦路斯',
    'ja-JP': 'キプロス',
  },
  SY: {
    'en-US': 'Syria',
    'zh-CN': '叙利亚',
    'ja-JP': 'シリア',
  },
  AM: {
    'en-US': 'Armenia',
    'zh-CN': '亚美尼亚',
    'ja-JP': 'アルメニア',
  },
  YE: {
    'en-US': 'Yemen',
    'zh-CN': '也门',
    'ja-JP': 'イエメン',
  },
  IQ: {
    'en-US': 'Iraq',
    'zh-CN': '伊拉克',
    'ja-JP': 'イラク',
  },
  JO: {
    'en-US': 'Jordan',
    'zh-CN': '约旦',
    'ja-JP': 'ヨルダン',
  },
  KR: {
    'en-US': 'South Korea',
    'zh-CN': '韩国',
    'ja-JP': '韓国',
  },
  JP: {
    'en-US': 'Japan',
    'zh-CN': '日本',
    'ja-JP': '日本',
  },
  CN: {
    'en-US': 'China',
    'zh-CN': '中国',
    'ja-JP': '中国',
  },
  MO: {
    'en-US': 'Macao SAR China',
    'zh-CN': '中国澳门',
    'ja-JP': '中国マカオ',
  },
  TW: {
    'en-US': 'Taiwan(China)',
    'zh-CN': '中国台湾',
    'ja-JP': '中国台湾',
  },
  HK: {
    'en-US': 'Hong Kong SAR China',
    'zh-CN': '中国香港',
    'ja-JP': '中国香港',
  },
  KP: {
    'en-US': 'North Korea',
    'zh-CN': '朝鲜',
    'ja-JP': '北朝鮮',
  },
  MN: {
    'en-US': 'Mongolia',
    'zh-CN': '蒙古',
    'ja-JP': 'モンゴル',
  },
  IN: {
    'en-US': 'India',
    'zh-CN': '印度',
    'ja-JP': 'インド',
  },
  PK: {
    'en-US': 'Pakistan',
    'zh-CN': '巴基斯坦',
    'ja-JP': 'パキスタン',
  },
  BT: {
    'en-US': 'Bhutan',
    'zh-CN': '不丹',
    'ja-JP': 'ブータン',
  },
  MV: {
    'en-US': 'Maldives',
    'zh-CN': '马尔代夫',
    'ja-JP': 'モルディブ',
  },
  BD: {
    'en-US': 'Bangladesh',
    'zh-CN': '孟加拉国',
    'ja-JP': 'バングラデシュ',
  },
  NP: {
    'en-US': 'Nepal',
    'zh-CN': '尼泊尔',
    'ja-JP': 'ネパール',
  },
  LK: {
    'en-US': 'Sri Lanka',
    'zh-CN': '斯里兰卡',
    'ja-JP': 'スリランカ',
  },
  CA: {
    'en-US': 'Canada',
    'zh-CN': '加拿大',
    'ja-JP': 'カナダ',
  },
  US: {
    'en-US': 'United States',
    'zh-CN': '美国',
    'ja-JP': 'アメリカ合衆国',
  },
  MX: {
    'en-US': 'Mexico',
    'zh-CN': '墨西哥',
    'ja-JP': 'メキシコ',
  },
  GL: {
    'en-US': 'Greenland',
    'zh-CN': '格陵兰',
    'ja-JP': 'グリーンランド',
  },
  AN: {
    'en-US': 'Netherlands Antilles',
    'zh-CN': '荷属安的列斯',
    'ja-JP': 'オランダ領アンティル',
  },
  PM: {
    'en-US': 'St. Pierre & Miquelon',
    'zh-CN': '圣皮埃尔和密克隆',
    'ja-JP': 'サンピエール島とミクロン島',
  },
  AR: {
    'en-US': 'Argentina',
    'zh-CN': '阿根廷',
    'ja-JP': 'アルゼンチン',
  },
  BR: {
    'en-US': 'Brazil',
    'zh-CN': '巴西',
    'ja-JP': 'ブラジル',
  },
  CO: {
    'en-US': 'Colombia',
    'zh-CN': '哥伦比亚',
    'ja-JP': 'コロンビア',
  },
  AW: {
    'en-US': 'Aruba',
    'zh-CN': '阿鲁巴',
    'ja-JP': 'アルバ',
  },
  AI: {
    'en-US': 'Anguilla',
    'zh-CN': '安圭拉',
    'ja-JP': 'アンギラ',
  },
  AG: {
    'en-US': 'Antigua & Barbuda',
    'zh-CN': '安提瓜和巴布达',
    'ja-JP': 'アンティグア・バーブーダ',
  },
  BB: {
    'en-US': 'Barbados',
    'zh-CN': '巴巴多斯',
    'ja-JP': 'バルバドス',
  },
  BS: {
    'en-US': 'Bahamas',
    'zh-CN': '巴哈马',
    'ja-JP': 'バハマ',
  },
  PY: {
    'en-US': 'Paraguay',
    'zh-CN': '巴拉圭',
    'ja-JP': 'パラグアイ',
  },
  PA: {
    'en-US': 'Panama',
    'zh-CN': '巴拿马',
    'ja-JP': 'パナマ',
  },
  BM: {
    'en-US': 'Bermuda',
    'zh-CN': '百慕大',
    'ja-JP': 'バミューダ',
  },
  PR: {
    'en-US': 'Puerto Rico',
    'zh-CN': '波多黎各',
    'ja-JP': 'プエルトリコ',
  },
  BO: {
    'en-US': 'Bolivia',
    'zh-CN': '玻利维亚',
    'ja-JP': 'ボリビア',
  },
  BZ: {
    'en-US': 'Belize',
    'zh-CN': '伯利兹',
    'ja-JP': 'ベリーズ',
  },
  DO: {
    'en-US': 'Dominican Republic',
    'zh-CN': '多米尼加',
    'ja-JP': 'ドミニカ共和国',
  },
  DM: {
    'en-US': 'Dominica',
    'zh-CN': '多米尼克',
    'ja-JP': 'ドミニカ',
  },
  EC: {
    'en-US': 'Ecuador',
    'zh-CN': '厄瓜多尔',
    'ja-JP': 'エクアドル',
  },
  MF: {
    'en-US': 'St. Martin',
    'zh-CN': '法属圣马丁',
    'ja-JP': 'フランス領サン・マルタン',
  },
  CR: {
    'en-US': 'Costa Rica',
    'zh-CN': '哥斯达黎加',
    'ja-JP': 'コスタリカ',
  },
  GD: {
    'en-US': 'Grenada',
    'zh-CN': '格林纳达',
    'ja-JP': 'グレナダ',
  },
  CU: {
    'en-US': 'Cuba',
    'zh-CN': '古巴',
    'ja-JP': 'キューバ',
  },
  GP: {
    'en-US': 'Guadeloupe',
    'zh-CN': '瓜德罗普',
    'ja-JP': 'グアドループ',
  },
  GY: {
    'en-US': 'Guyana',
    'zh-CN': '圭亚那',
    'ja-JP': 'ガイアナ',
  },
  HT: {
    'en-US': 'Haiti',
    'zh-CN': '海地',
    'ja-JP': 'ハイチ',
  },
  SX: {
    'en-US': 'Sint Maarten',
    'zh-CN': '荷属圣马丁',
    'ja-JP': 'オランダ領サン・マルタン',
  },
  HN: {
    'en-US': 'Honduras',
    'zh-CN': '洪都拉斯',
    'ja-JP': 'ホンジュラス',
  },
  KY: {
    'en-US': 'Cayman Islands',
    'zh-CN': '开曼群岛',
    'ja-JP': 'ケイマン諸島',
  },
  CW: {
    'en-US': 'Curaçao',
    'zh-CN': '库拉索',
    'ja-JP': 'キュラソー',
  },
  MQ: {
    'en-US': 'Martinique',
    'zh-CN': '马提尼克',
    'ja-JP': 'マルティニーク',
  },
  MS: {
    'en-US': 'Montserrat',
    'zh-CN': '蒙特塞拉特',
    'ja-JP': 'モンセラット',
  },
  PE: {
    'en-US': 'Peru',
    'zh-CN': '秘鲁',
    'ja-JP': 'ペルー',
  },
  NI: {
    'en-US': 'Nicaragua',
    'zh-CN': '尼加拉瓜',
    'ja-JP': 'ニカラグア',
  },
  SV: {
    'en-US': 'El Salvador',
    'zh-CN': '萨尔瓦多',
    'ja-JP': 'エルサルバドル',
  },
  KN: {
    'en-US': 'St. Kitts & Nevis',
    'zh-CN': '圣基茨和尼维斯',
    'ja-JP': 'セントキッツ・ネイビス',
  },
  LC: {
    'en-US': 'St. Lucia',
    'zh-CN': '圣卢西亚',
    'ja-JP': 'セントルシア',
  },
  VC: {
    'en-US': 'St. Vincent & Grenadines',
    'zh-CN': '圣文森特和格林纳丁斯',
    'ja-JP': 'セントビンセント・グレナディーン',
  },
  TC: {
    'en-US': 'Turks & Caicos Islands',
    'zh-CN': '特克斯和凯科斯群岛',
    'ja-JP': 'タークス・カイコス諸島',
  },
  TT: {
    'en-US': 'Trinidad & Tobago',
    'zh-CN': '特立尼达和多巴哥',
    'ja-JP': 'トリニダード・トバゴ',
  },
  GT: {
    'en-US': 'Guatemala',
    'zh-CN': '危地马拉',
    'ja-JP': 'グアテマラ',
  },
  VE: {
    'en-US': 'Venezuela',
    'zh-CN': '委内瑞拉',
    'ja-JP': 'ベネズエラ',
  },
  UY: {
    'en-US': 'Uruguay',
    'zh-CN': '乌拉圭',
    'ja-JP': 'ウルグアイ',
  },
  JM: {
    'en-US': 'Jamaica',
    'zh-CN': '牙买加',
    'ja-JP': 'ジャマイカ',
  },
  VG: {
    'en-US': 'British Virgin Islands',
    'zh-CN': '英属维尔京群岛',
    'ja-JP': '英領バージン諸島',
  },
  CL: {
    'en-US': 'Chile',
    'zh-CN': '智利',
    'ja-JP': 'チリ',
  },
};

// 国家id-二位码 对应表
export const CountryCodeList: Record<string, string> = {
  '100': 'ID',
  '102': 'MY',
  '104': 'VN',
  '106': 'PH',
  '108': 'TH',
  '110': 'SG',
  '112': 'LA',
  '114': 'KH',
  '116': 'MM',
  '118': 'BN',
  '120': 'TL',
  '122': 'KR',
  '124': 'JP',
  '126': 'TW',
  '128': 'HK',
  '130': 'MO',
  '132': 'CN',
  '134': 'MN',
  '136': 'KP',
  '138': 'IN',
  '140': 'PK',
  '142': 'BD',
  '144': 'NP',
  '146': 'LK',
  '148': 'MV',
  '150': 'BT',
  '152': 'RU',
  '154': 'UA',
  '156': 'BY',
  '158': 'MD',
  '160': 'TJ',
  '162': 'KZ',
  '164': 'KG',
  '166': 'UZ',
  '168': 'TM',
  '170': 'SA',
  '172': 'IR',
  '174': 'IQ',
  '176': 'KW',
  '178': 'AE',
  '180': 'OM',
  '182': 'QA',
  '184': 'BH',
  '186': 'TR',
  '188': 'IL',
  '190': 'PS',
  '192': 'SY',
  '194': 'LB',
  '196': 'JO',
  '198': 'YE',
  '200': 'CY',
  '202': 'GE',
  '204': 'AM',
  '206': 'AZ',
  '208': 'EG',
  '210': 'LY',
  '212': 'TN',
  '214': 'DZ',
  '216': 'MA',
  '218': 'XI',
  '220': 'XH',
  '222': 'EH',
  '224': 'XA',
  '226': 'SD',
  '228': 'SS',
  '230': 'ET',
  '232': 'ER',
  '234': 'SO',
  '236': 'DJ',
  '238': 'KE',
  '240': 'TZ',
  '242': 'UG',
  '244': 'RW',
  '246': 'BI',
  '248': 'SC',
  '250': 'ZA',
  '252': 'ZM',
  '254': 'AO',
  '256': 'ZW',
  '258': 'MU',
  '260': 'MG',
  '262': 'SZ',
  '264': 'KM',
  '266': 'NA',
  '268': 'MW',
  '270': 'MZ',
  '272': 'BW',
  '274': 'LS',
  '276': 'RE',
  '278': 'SH',
  '280': 'NG',
  '282': 'NE',
  '284': 'MR',
  '286': 'SN',
  '288': 'GM',
  '290': 'ML',
  '292': 'BF',
  '294': 'GN',
  '296': 'GW',
  '298': 'CV',
  '300': 'SL',
  '302': 'LR',
  '304': 'CI',
  '306': 'GH',
  '308': 'TG',
  '310': 'BJ',
  '312': 'TD',
  '314': 'CM',
  '316': 'GQ',
  '318': 'GA',
  '320': 'ST',
  '322': 'US',
  '324': 'CA',
  '326': 'MX',
  '328': 'GL',
  '330': 'PM',
  '332': 'BR',
  '334': 'CO',
  '336': 'AR',
  '338': 'PE',
  '340': 'CL',
  '342': 'JM',
  '344': 'PA',
  '346': 'CU',
  '348': 'BS',
  '350': 'UY',
  '352': 'PY',
  '354': 'EC',
  '356': 'VE',
  '358': 'GT',
  '360': 'BO',
  '362': 'NI',
  '364': 'CR',
  '366': 'HT',
  '368': 'DO',
  '370': 'AG',
  '372': 'KN',
  '374': 'DM',
  '376': 'LC',
  '378': 'VC',
  '380': 'GD',
  '382': 'BB',
  '384': 'TT',
  '386': 'PR',
  '388': 'VG',
  '390': 'AI',
  '392': 'MS',
  '394': 'GP',
  '396': 'MQ',
  '398': 'AW',
  '400': 'CW',
  '402': 'SX',
  '404': 'TC',
  '406': 'KY',
  '408': 'BM',
  '410': 'MF',
  '412': 'BZ',
  '414': 'SV',
  '416': 'HN',
  '418': 'FR',
  '420': 'GB',
  '422': 'DE',
  '424': 'AT',
  '426': 'ES',
  '428': 'PT',
  '430': 'IT',
  '432': 'GR',
  '434': 'SE',
  '436': 'NO',
  '438': 'DK',
  '440': 'FI',
  '442': 'IS',
  '444': 'CH',
  '446': 'IE',
  '448': 'NL',
  '450': 'PL',
  '452': 'CZ',
  '454': 'SK',
  '456': 'HU',
  '458': 'LI',
  '460': 'BE',
  '462': 'LU',
  '464': 'MC',
  '466': 'RO',
  '468': 'BG',
  '470': 'RS',
  '472': 'MK',
  '474': 'AL',
  '476': 'SI',
  '478': 'HR',
  '480': 'ME',
  '482': 'MT',
  '484': 'VA',
  '486': 'AD',
  '488': 'SM',
  '490': 'EE',
  '492': 'LV',
  '494': 'LT',
  '496': 'FO',
  '498': 'GI',
  '500': 'BA',
  '502': 'AU',
  '504': 'NZ',
  '506': 'PW',
  '508': 'FM',
  '510': 'MH',
  '512': 'KI',
  '514': 'NR',
  '516': 'MP',
  '518': 'GU',
  '520': 'PG',
  '522': 'SB',
  '524': 'VU',
  '526': 'FJ',
  '528': 'NC',
  '530': 'TV',
  '532': 'WS',
  '534': 'TO',
  '536': 'CK',
  '538': 'NU',
  '540': 'TK',
  '542': 'PF',
  '544': 'WF',
  '546': 'PN',
  '548': 'AS',
  '550': 'YT',
  '552': 'JE',
  '554': 'IM',
  '556': 'GG',
  '558': 'AN',
  '560': 'GY',
  '562': 'AF',
  '564': 'CD',
};
